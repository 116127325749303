import { Percent, Token, BaseCurrency, Price, ZERO, ONE, InsufficientReservesError, _9970, _10000, CurrencyAmount, InsufficientInputAmountError, _9975, sqrt, MINIMUM_LIQUIDITY, TradeType, computePriceImpact, Fraction, sortedInsert, NativeCurrency, FIVE } from '@pancakeswap/swap-sdk-core';
export * from '@pancakeswap/swap-sdk-core';
import { ChainId } from '@pancakeswap/chains';
export { ChainId } from '@pancakeswap/chains';
import NineInchConfig from '@9inch/config';
import invariant5 from 'tiny-invariant';
import { createPublicClient, http, getAddress, keccak256, encodePacked, getContract, toBytes, pad, isBytes, slice, concat } from 'viem';
import warning from 'tiny-warning';
import { mainnet, bsc, bscTestnet, goerli } from 'viem/chains';

// src/constants.ts

// src/v2config.json
var v2config_default = {
  "1": {
    PEPE: "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
    TRUMP: "0x576e2BeD8F7b46D34016198911Cdf9886f78bea7",
    SHIB: "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
    FLOKI: "0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E",
    MOG: "0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a",
    BEN: "0xdcc97D2C1048e8F3F2Fc58ACE9024AB8b350e4B1",
    WPLS: "0xA882606494D86804B5514E07e6Bd2D6a6eE6d68A",
    WHETH: "0xDe0220b69CE3e855a0124433A8E8D093f53A6bE4",
    DBI: "0x2dE509bf0014ddF697b220bE628213034d320EcE",
    PP: "0x9565c2036963697786705120Fc59310F747bCfD0",
    PLD: "0x52Ada28F70BC8EBe5dd4381120d3CD76863919A8",
    LINK: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    HEX: "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
    IM: "0x0A58153a0CD1cfaea94cE1f7FdC5D7E679eCa936",
    TIME: "0xd08481058399490B83a72676901d4e9dB70E75aC",
    TEXAN: "0xcFCFfE432A48dB53F59c301422d2EdD77B2A88d7",
    MORE: "0xbEEf3bB9dA340EbdF0f5bae2E85368140d7D85D0",
    XEN: "0x06450dEe7FD2Fb8E39061434BAbCFC05599a6Fb8",
    ePhiat: "0xE9F721E7419423f11863e83DbD710b5D6127b5b0",
    HDRN: "0x3819f64f282bf135d62168C1e513280dAF905e06",
    ICSA: "0xfc4913214444aF5c715cc9F7b52655e788A569ed",
    LUCKY: "0x6B0956258fF7bd7645aa35369B55B61b8e6d6140",
    KIRA: "0xf98AB0874b13a7Fdc39D7295DEdd49850A5D426B",
    SSH: "0x07445312dB273589dcb0fd2bd57aa815A5Afb8CC",
    WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    DAI: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
    NineInch: "0xFD8b9Ba4845fB38c779317eC134b298C064937a2",
    BBC: "0x015628Ce9150Db1bCE2FBb717A09E846F8a32436",
    BITCOIN: "0x72e4f9F808C49A2a61dE9C5896298920Dc4EEEa9",
    NineInchFactory: "0xcBAE5C3f8259181EB7E2309BC4c72fDF02dD56D8",
    INIT_CODE_HASH: "0xd2cf61d4acad30e9fe5ec59d0f94de554d88701f1bd8fc635546866716718511",
    NineInchRouter: "0xa79882a5Bcd455c6e582dAD43f3f3F2c9C8264EB",
    ETH_DAI: "0xE62C42b9857477c30e449247FA77ADcF3E450834",
    ETH_PP: "0xE1b221AF0F956631cFB99bae7c31a4e07dA9B5be",
    ETH_9INCH: "0xe0533126C4013e2F5bcF44a2c84A396219BE2d9d",
    ETH_BBC: "0xA0CA10e4627F31C473a2D7A012EA81ac8D8FD949",
    "9INCH_DAI": "0x8Bd89A58F8354a1012a2BA83A4CAdb457C61375A",
    "9INCH_USDC": "0x1f8C1B7F5f826062dA03Fba69350DBEBc4a76392",
    "9INCH_USDT": "0xD07EBAb25E3B0cba2A75c36270ea35CB17434F15",
    "9INCH_LINK": "0xf520a536f5eb4B7B2Dbf05c28C104f35961889Ac",
    "9INCH_BBC": "0xcB1A01c4b3217472Ba74CA3e8F217EE0D0531eD6",
    MasterChef: "0x3fefd06828689252A69207718985B9a78350561F",
    OrderBook: "0xC5B17DdacF4Dc0BDBEbE3043B95bF8Fd8e6aAa8e",
    NineInchBuyAndBurn: "0xEe591146A6E6eA55db3F7ed236887A25FA406826",
    "9INCH_ETH": "0xe0533126C4013e2F5bcF44a2c84A396219BE2d9d",
    Pools: {
      "0xe0533126C4013e2F5bcF44a2c84A396219BE2d9d": 1,
      "0x8Bd89A58F8354a1012a2BA83A4CAdb457C61375A": 2,
      "0x1f8C1B7F5f826062dA03Fba69350DBEBc4a76392": 3,
      "0xD07EBAb25E3B0cba2A75c36270ea35CB17434F15": 4,
      "0xcB1A01c4b3217472Ba74CA3e8F217EE0D0531eD6": 5,
      "0xE1b221AF0F956631cFB99bae7c31a4e07dA9B5be": 6,
      "0x688a56B8DE7D705Dddf0fe21F35b467081bb45FD": 7,
      "0x40f18C90b85B8Bb3201d2F0f61AaD410A4E0B7D8": 8,
      "0x604958ae2C28DA20B593ca132E3e5b16e3fD49c5": 9,
      "0x80721b57076970170B41DCbE151521184eC3a86c": 10,
      "0x95d64bF395BecEe313ab3498f1F13D77a5DEe2dd": 11,
      "0x03f4232B6a6e4d705424679f3b0f7304cdBE7d25": 12,
      "0x28EB1804401B8d84751d6EB77010E8fA1B377E25": 13,
      "0x2FEBA6992aAA7B2397E5B212AE060C76857Cf9Bb": 14,
      "0xa759Ae56Db0bDd940163a18061218673bDE8E519": 15,
      "0xa1E94f686a1BaA4196852b67200A07E25A4814A8": 16,
      "0xc6ce3F8fC142CC96879C53426ceEAb64Eb9e5C6f": 17,
      "0x66F7462B5178Fcf5e31635f5A1648C0f2A65786F": 18,
      "0x913e5c7198dbFB069AfCC38F1682a69C4b8bC9Ed": 19,
      "0xBc38cCDb8c84f6FddC74e43aB3B3EC82c1902C59": 20,
      "0x9436CaCb51D468b628300CC79bA057D347AFF4E6": 21,
      "0xB617BF4667F54AB82fC91c2d49a0A223E12C0E6b": 22,
      "0xecd0a81936663A83D28c8CaD5d04b2feae519e5f": 23,
      "0xd243447C3E5945535c81bfc03F8DC4363919C463": 24,
      "0x362D4073eb7583D6dffa15029918BCd531Faa0bC": 25,
      "0x2C4BAdF3C89deDEfE5c03e42009D25d7E4113012": 26,
      "0xD440CA5d80BD9771b0080AeD44F702cB68010a7b": 27,
      "0x9C7C1F0D7bA90817F9E8B18CaA83bBBB0ddA0C51": 28,
      "0xD333868dcba6F95dBFaFC1d86198A87f47C4bCce": 29,
      "0xEAF45dEA447e882BbDa8a12CAD0d90DadC21d9A5": 30
    },
    NineInchVault: "0x9b2B253D75DC61FC9ae512e04850e258bEbEf8C6",
    NineInchFlexibleVault: "0x0e173B11523feB064FD483209d0eD534831A2a86",
    CakeVault: "0x91c58Cf141ABBEB6aB8D83976103Bca70b69C24e",
    CakeFlexibleVault: "0xdf598bfe7b8eB5ABd217871317E31a48d9E4432F",
    VotePower: "0x28bDa90B016B66dF1991cD99bc18D11807410099",
    "9INCH_PP": "0x688a56B8DE7D705Dddf0fe21F35b467081bb45FD",
    ETH_PLD: "0x40f18C90b85B8Bb3201d2F0f61AaD410A4E0B7D8",
    "9INCH_PLD": "0x604958ae2C28DA20B593ca132E3e5b16e3fD49c5",
    ETH_DBI: "0x80721b57076970170B41DCbE151521184eC3a86c",
    "9INCH_DBI": "0x28EB1804401B8d84751d6EB77010E8fA1B377E25",
    ETH_WHETH: "0x95d64bF395BecEe313ab3498f1F13D77a5DEe2dd",
    "9INCH_WHETH": "0x03f4232B6a6e4d705424679f3b0f7304cdBE7d25",
    PpVault: "0xa1EAee97ee29e2C80ee9F1321E0132f19b45A26A",
    PpFlexibleVault: "0x3eFC853438e9c06130D104088d73647517617887",
    PldVault: "0xdBD90Fc90101Bdd5EE0e8b2C26A32c9E59047415",
    PldFlexibleVault: "0x0022E0C25BbA451b08942367b98e4B4a617538f2",
    WhethVault: "0x664e78C17d64234440A26CA72a6946A270251059",
    WhethFlexibleVault: "0x211F7596Db264469c4114db5C41b86E173B0A29a",
    DbiVault: "0xB9BB00965AC5c8e8b261243C3C442E3F00B82C1F",
    DbiFlexibleVault: "0xEA01a51a675170d4939C1439d558Eb3B896C29Ec",
    ETH_HEX: "0x2FEBA6992aAA7B2397E5B212AE060C76857Cf9Bb",
    "9INCH_HEX": "0xa759Ae56Db0bDd940163a18061218673bDE8E519",
    BBC_HEX: "0xa1E94f686a1BaA4196852b67200A07E25A4814A8",
    ETH_WPLS: "0xc6ce3F8fC142CC96879C53426ceEAb64Eb9e5C6f",
    "9INCH_WPLS": "0x66F7462B5178Fcf5e31635f5A1648C0f2A65786F",
    EWALES: "0x1946c4EDF247F4fc4ff390237ECAeeb87D38C565",
    ETH_PEPE: "0x913e5c7198dbFB069AfCC38F1682a69C4b8bC9Ed",
    ETH_TRUMP: "0xBc38cCDb8c84f6FddC74e43aB3B3EC82c1902C59",
    ETH_SHIB: "0x9436CaCb51D468b628300CC79bA057D347AFF4E6",
    ETH_FLOKI: "0xB617BF4667F54AB82fC91c2d49a0A223E12C0E6b",
    ETH_MOG: "0xecd0a81936663A83D28c8CaD5d04b2feae519e5f",
    ETH_BEN: "0xd243447C3E5945535c81bfc03F8DC4363919C463",
    PepeVault: "0x698C4B5c3ff6B659c8e36fFb2f61e06f4cf3da56",
    PepeFlexibleVault: "0x5419f8508D9ff142260EB8af16CF6876f4a1f814",
    ShibVault: "0xb77c20fD87386B1D871398f824bb103a803241D5",
    ShibFlexibleVault: "0xD6fB30ec247ca3B2AD2Afd96af05eFa0561684a6",
    BenVault: "0xD77Ae4371f49Aeb378ef3729004d2fB08Af1d18e",
    BenFlexibleVault: "0x6dd836151b0C7cA7D3B91bb323a415A2F5785D8F",
    BBC_PEPE: "0x362D4073eb7583D6dffa15029918BCd531Faa0bC",
    BBC_TRUMP: "0x2C4BAdF3C89deDEfE5c03e42009D25d7E4113012",
    BBC_SHIB: "0xD440CA5d80BD9771b0080AeD44F702cB68010a7b",
    BBC_FLOKI: "0x9C7C1F0D7bA90817F9E8B18CaA83bBBB0ddA0C51",
    BBC_MOG: "0xD333868dcba6F95dBFaFC1d86198A87f47C4bCce",
    BBC_BEN: "0xEAF45dEA447e882BbDa8a12CAD0d90DadC21d9A5"
  },
  "5": {
    WETH: "0xb16F35c0Ae2912430DAc15764477E179D9B9EbEa",
    USDC: "0x42f2f7Ead1429d58640Ab7DAe66FeA2E34bC10a9",
    NineInch: "0x2F269baf878454Ea0052f2dddcD68D9D1D64bf5A",
    BBC: "0xC6062eeeca221462a0C7e5ff8A99874B7054bB48",
    HDRN: "0x457F1C7FA874DeBc56CAAD64649700082f1E3A24",
    TEXAN: "0x587b49A63663AcdC1046b3BfFe7182a2ED2A4622",
    ICSA: "0x23A36cA185FE01DD49C1AD8AF19DB39aa230D230",
    LUCKY: "0xF857b849eB3ed8e1b5Ec77eB4B216163E58757A9",
    POLY: "0x46b3d710543E75b1AeF6b3193a85A1f6113F01D6",
    PLSD: "0x756766fF8a670C14536eF15eA575D601B8f29fb3",
    PLSB: "0x37B9012c1dA05aca22c2B5375aaB67e6385EB286",
    PP: "0xcc790C02E3e0970e3A0B9C803c4cFC891F2B7a83",
    MORE: "0x46C2B651f78d45F7990dE8D4740fE24476e895Ad",
    PLD: "0x950C1c03749E0EC94A5e179f9700e4BFc4e2d790",
    PZEN: "0x9aed63f4de7714640664D61A30B8833FfE09bd14",
    COM: "0x3006Fe314a3f903577214Aa0FA1Fe68F2dC846C1",
    DBI: "0xb554e140d3c925C16141b239DB08199f27F20D6b",
    XEN: "0x43bf7c2325eb79a0c508e96C17814A6eC91663Bd",
    HEX: "0x71D141E777fBA871D4E1CeD575ABBFE3602Fc08f",
    aPhiat: "0xb0AF362a37Edc64B5E643555EdD37002924F6ac0",
    DXN: "0xB5DB0529F38EE145Be5cf91eF263282EF9FCCaD3",
    XLON: "0x4CeA83b41e9b0E62B3637d1Eca92316775EED65c",
    NineInchFactory: "0x16fAb17d5c672CA23F8C6e005ad6fF87289B4Adb",
    NineInchRouter: "0x163c5A18103fcFA26D60c4641b33eCB2E7886019",
    INIT_CODE_HASH: "0x9be2c3d4e6c89bfb79fda3ae7e260961b2af50ef8361e0de46f6a321a49006de",
    USDC_WETH: "0xecE0954242568e142B8aC17683268bd4F4f7b7a3",
    BBC_9INCH: "0x76e9c2410d2e9E0f3ef5f8061FebaB52fa263FFb",
    ETH_9INCH: "0x6A6056BC5e261FFDd4351958929C06a8f746626F",
    ETH_BBC: "0x6822d2d8334e572D6490d0612D5683A458aeE4b6",
    ETH_HEX: "0x1801D8765045ddFB302572af68cf99233427e391",
    ETH_DBI: "0xa241cc2e8aFd358aD66A3Aa0519f58C7EBd13DFE",
    ETH_PP: "0xa241cc2e8aFd358aD66A3Aa0519f58C7EBd13DFE",
    ETH_PLD: "0xa241cc2e8aFd358aD66A3Aa0519f58C7EBd13DFE",
    ETH_HDRN: "0xa241cc2e8aFd358aD66A3Aa0519f58C7EBd13DFE",
    ETH_XEN: "0x13C4Cc2B9D2687966275165a68E00371F9462317",
    MasterChef: "0xbC48E72f5D09D556A9AB144b38B3fCf90966dB4a",
    CakeVault: "0x50C016810BaA07753d8EED36C557CbC654E5DBe8",
    CakeFlexibleVault: "0x77B6d0BeA45844CAD3E619b6d0fF95c0077Fd7ff",
    SmartChef: "0x2E3c19950B8f92EFc65372f67387f9DB29E8a1D0",
    NineInchBuyAndBurn: "0x528A4d8dB8B6196a76f25B338DAB7604b0FdcF58"
  },
  "56": {
    WETH: "0xb16F35c0Ae2912430DAc15764477E179D9B9EbEa",
    USDC: "0x42f2f7Ead1429d58640Ab7DAe66FeA2E34bC10a9",
    NineInch: "0x2F269baf878454Ea0052f2dddcD68D9D1D64bf5A",
    BBC: "0xC6062eeeca221462a0C7e5ff8A99874B7054bB48",
    HDRN: "0x457F1C7FA874DeBc56CAAD64649700082f1E3A24",
    TEXAN: "0x587b49A63663AcdC1046b3BfFe7182a2ED2A4622",
    ICSA: "0x23A36cA185FE01DD49C1AD8AF19DB39aa230D230",
    LUCKY: "0xF857b849eB3ed8e1b5Ec77eB4B216163E58757A9",
    POLY: "0x46b3d710543E75b1AeF6b3193a85A1f6113F01D6",
    PLSD: "0x756766fF8a670C14536eF15eA575D601B8f29fb3",
    PLSB: "0x37B9012c1dA05aca22c2B5375aaB67e6385EB286",
    PP: "0xcc790C02E3e0970e3A0B9C803c4cFC891F2B7a83",
    MORE: "0x46C2B651f78d45F7990dE8D4740fE24476e895Ad",
    PLD: "0x950C1c03749E0EC94A5e179f9700e4BFc4e2d790",
    PZEN: "0x9aed63f4de7714640664D61A30B8833FfE09bd14",
    COM: "0x3006Fe314a3f903577214Aa0FA1Fe68F2dC846C1",
    DBI: "0xb554e140d3c925C16141b239DB08199f27F20D6b",
    XEN: "0x43bf7c2325eb79a0c508e96C17814A6eC91663Bd",
    HEX: "0x71D141E777fBA871D4E1CeD575ABBFE3602Fc08f",
    aPhiat: "0xb0AF362a37Edc64B5E643555EdD37002924F6ac0",
    DXN: "0xB5DB0529F38EE145Be5cf91eF263282EF9FCCaD3",
    XLON: "0x4CeA83b41e9b0E62B3637d1Eca92316775EED65c",
    NineInchFactory: "0x16fAb17d5c672CA23F8C6e005ad6fF87289B4Adb",
    NineInchRouter: "0x163c5A18103fcFA26D60c4641b33eCB2E7886019",
    INIT_CODE_HASH: "0x9be2c3d4e6c89bfb79fda3ae7e260961b2af50ef8361e0de46f6a321a49006de",
    USDC_WETH: "0xecE0954242568e142B8aC17683268bd4F4f7b7a3",
    BBC_9INCH: "0x76e9c2410d2e9E0f3ef5f8061FebaB52fa263FFb",
    ETH_9INCH: "0x6A6056BC5e261FFDd4351958929C06a8f746626F",
    ETH_BBC: "0x6822d2d8334e572D6490d0612D5683A458aeE4b6",
    ETH_HEX: "0x1801D8765045ddFB302572af68cf99233427e391",
    ETH_DBI: "0xa241cc2e8aFd358aD66A3Aa0519f58C7EBd13DFE",
    ETH_PP: "0xa241cc2e8aFd358aD66A3Aa0519f58C7EBd13DFE",
    ETH_PLD: "0xa241cc2e8aFd358aD66A3Aa0519f58C7EBd13DFE",
    ETH_HDRN: "0xa241cc2e8aFd358aD66A3Aa0519f58C7EBd13DFE",
    ETH_XEN: "0x13C4Cc2B9D2687966275165a68E00371F9462317",
    MasterChef: "0xbC48E72f5D09D556A9AB144b38B3fCf90966dB4a",
    CakeVault: "0x50C016810BaA07753d8EED36C557CbC654E5DBe8",
    CakeFlexibleVault: "0x77B6d0BeA45844CAD3E619b6d0fF95c0077Fd7ff",
    SmartChef: "0x2E3c19950B8f92EFc65372f67387f9DB29E8a1D0",
    NineInchBuyAndBurn: "0x528A4d8dB8B6196a76f25B338DAB7604b0FdcF58"
  },
  "369": {
    FIVE_HUNDRED_THIRTEEN: "0x296ec1064Fe41E3625c02007E80B64714922369c",
    WETH: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
    PLS: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
    CLOWN: "0xE44865C4B209963a9D0a2482c1ceeaE5bbD12D61",
    USDC: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
    USDT: "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f",
    DAI: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
    PLSX: "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab",
    DBI: "0xB3bC21348882A3866d9b369628462B1C0d507904",
    NineInch: "0x3ca80d83277e721171284667829c686527B8b3c5",
    BBC: "0x8b4cfb020aF9AcAd95AD80020cE8f67FBB2C700E",
    PLD: "0x52Ada28F70BC8EBe5dd4381120d3CD76863919A8",
    PP: "0x9565c2036963697786705120Fc59310F747bCfD0",
    W9INCH: "0xeD22494279e0F3F1Ab491F823c6eC4055afeB21F",
    eBBC: "0xc948C04e895a317E71eB2721a129D69c690aAf00",
    HEX: "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
    eHEX: "0x57fde0a71132198BBeC939B98976993d8D89D225",
    IM: "0xBBcF895BFCb57d0f457D050bb806d1499436c0CE",
    TIME: "0xCA35638A3fdDD02fEC597D8c1681198C06b23F58",
    TEXAN: "0xcFCFfE432A48dB53F59c301422d2EdD77B2A88d7",
    MORE: "0xbEEf3bB9dA340EbdF0f5bae2E85368140d7D85D0",
    pXEN: "0x8a7FDcA264e87b6da72D000f22186B4403081A2a",
    PHIAT: "0x96E035ae0905EFaC8F733f133462f971Cfa45dB1",
    PHUX: "0x9663c2d75ffd5F4017310405fCe61720aF45B829",
    BEAR: "0xd6c31bA0754C4383A41c0e9DF042C62b5e918f6d",
    RBC: "0x43eaba2E2d2F32f1207A11a18679287Dc7700015",
    pDAI: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    MINT: "0x207e6b4529840A4fd518f73c68bc9c19B2A15944",
    MEGA: "0x8eDb13CE75562056DFf2221D193557Fb4A05770D",
    PTS: "0x2A06a971fE6ffa002fd242d437E3db2b5cC5B433",
    HDRN: "0x3819f64f282bf135d62168C1e513280dAF905e06",
    ICSA: "0xfc4913214444aF5c715cc9F7b52655e788A569ed",
    PLN: "0xa685C45fd071DF23278069Db9137e124564897D0",
    LUCKY: "0x6B0956258fF7bd7645aa35369B55B61b8e6d6140",
    BEET: "0xeDA0073B4Aa1b1B6f9c718c3036551ab46E5Ec32",
    PZEN: "0x5a24D7129B6f3FcAd2220296df28911880AD22B0",
    HOA: "0x7901a3569679AEc3501dbeC59399F327854a70fe",
    KIRA: "0xf98AB0874b13a7Fdc39D7295DEdd49850A5D426B",
    pWBTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    pBTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    pUSDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    pUSDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    pETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    BOBO: "0x645c33E6ecC5e5FD67Bcc248cAd29B1950E469C6",
    TF: "0x2EAFB65f0eE80D7b4DcCe7b472802e098a98EcD9",
    ANON: "0x075F7F657AEAD0e698EDb4E0A47d1DEF869536B4",
    GRANN: "0xfCf7F3915A899b9133b0D10f6b84F6a849C212Df",
    ANONIN: "0x9dfCc52f07C697b9E63bC84bCB588A124A439862",
    FLOP: "0xA8B4e655d77aC9AE98521b53bB6b9b26a7F93c68",
    GOBURN: "0xe0Cbe9d454AE422D51b1E41c9AFc262b0f981b59",
    WATT: "0xDfdc2836FD2E63Bba9f0eE07901aD465Bff4DE71",
    PLSC: "0x4C8218Dc22e478963c02748857245FAD79AAd0c6",
    LOAN: "0x9159f1D2a9f51998Fc9Ab03fbd8f265ab14A1b3B",
    USDL: "0x0dEEd1486bc52aA0d3E6f8849cEC5adD6598A162",
    SQRT: "0x706c61AaEd35EEdEa4EFEAA76Fe3765012eeC6Ec",
    MOON: "0x8E64f0fCa8f0219cEF329ae593Ad6490BE122Ecb",
    SER: "0xaE19eDCb8C76B5Ebf07660af38620BF7A63a4c4c",
    BSTRD: "0xD738328b589c6fbfd1Dcff107C4243c73b2e05a9",
    BOOB: "0xC5eB04f668A4654C4257d6b40bea9f7Fe4d3cc0A",
    WHEDEX: "0xc61271baf566B14Ab7f16ec5845654BE2e76fE40",
    NUGT: "0xc77B97E9B23b313372f9F01397558Ef314cf0FE3",
    PINUP: "0xe83034A7A78fC148c69defebD6D4C80f8Bb4f710",
    PINU: "0xa12E2661ec6603CBbB891072b2Ad5b3d5edb48bd",
    WHETH: "0xDe0220b69CE3e855a0124433A8E8D093f53A6bE4",
    eWETH: "0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C",
    WBTC: "0xb17D901469B9208B17d916112988A3FeD19b5cA1",
    INC: "0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d",
    NOPE: "0x8D36123903f504eB81eEB832727af517c0db26bD",
    NineInchFactory: "0x5b9F077A77db37F3Be0A5b5d31BAeff4bc5C0bD7",
    INIT_CODE_HASH: "0xd2cf61d4acad30e9fe5ec59d0f94de554d88701f1bd8fc635546866716718511",
    NineInchRouter: "0xeB45a3c4aedd0F47F345fB4c8A1802BB5740d725",
    ETH_DAI: "0x55E8ed3762E976d440234476ED0D5c2202Cb24FB",
    PLS_DAI: "0x55E8ed3762E976d440234476ED0D5c2202Cb24FB",
    "9INCH_PLS": "0x1164daB36Cd7036668dDCBB430f7e0B15416EF0b",
    ETH_BBC: "0xed35E0e3472Fd645D8c4429721541Fd317493CE2",
    PLS_BBC: "0xed35E0e3472Fd645D8c4429721541Fd317493CE2",
    "9INCH_DAI": "0x31AcF819060AE711f63BD6b682640598E250C689",
    "9INCH_USDC": "0x6C5a0F22b459973A0305e2a565fc208a35A13850",
    "9INCH_USDT": "0x5449a776797B55A4AaC0B4A76B2AC878BFF3D3e3",
    "9INCH_PLSX": "0x898BB93f4629c73f0c519415a85d6BD2977CB0b5",
    "9INCH_BBC": "0xb543812ddEbC017976f867Da710ddb30cCA22929",
    MasterChef: "0x444775Ae2C82560337c86f6D62909a63381De4fd",
    OrderBook: "0x50c633F16454f80e0800AEdd82Bd4B4dA6f0F054",
    NineInchDCA: "0x37314f2dC7B7D232Bde3fCA7139FeA951374F61f",
    NineInchBuyAndBurn: "0x3fefd06828689252A69207718985B9a78350561F",
    "9INCH_W9INCH": "0x097d19b2061C5f31B68852349187C664920b4BA4",
    Pools: {
      "0x1164daB36Cd7036668dDCBB430f7e0B15416EF0b": 1,
      "0x31AcF819060AE711f63BD6b682640598E250C689": 2,
      "0x6C5a0F22b459973A0305e2a565fc208a35A13850": 3,
      "0x5449a776797B55A4AaC0B4A76B2AC878BFF3D3e3": 4,
      "0xb543812ddEbC017976f867Da710ddb30cCA22929": 5,
      "0x097d19b2061C5f31B68852349187C664920b4BA4": 6,
      "0x37515F5F2bb8a45Ae7CceF8653762a28cc8d4e3E": 7,
      "0x330a3a0a05f8F3Cc7Bb1372e98EcaCa75fcbDc69": 8,
      "0x0030176f066CE0929A31ea87697Dc51c715f8bEf": 9,
      "0x9F7578Fef8c1Fba986d2db83e4b1Fa5F1ECb7324": 10,
      "0x828cc8423fc97817CaD78C8bFBbA26923444dF60": 11,
      "0x329958477eaa4BFC9A036BA620a7E71dE75d44D4": 12,
      "0xB89ea01677529A8bbCECE3a486404880f9463C45": 13,
      "0x898BB93f4629c73f0c519415a85d6BD2977CB0b5": 14,
      "0xF34157A1aeCfeEf326C1C33d90802354abc5a702": 15,
      "0xF7bf31eed070538A5Ad491F7c0E57Bd717740575": 16,
      "0xf3499Cd797367DB82C230D9AcBCB370f8d38D78A": 17,
      "0x8948940e76eFc27B5e3253eA30aCc8e325a9cCa2": 18,
      "0xb823F30963511fb0a083073763f55d1285900C0c": 19,
      "0x633055AA25f33bbAe94091bbB753DD3b1849De0e": 20,
      "0x13f31A66a0F844d0bcfE1832A3C7096dd4BDE79E": 21,
      "0x8e680888C29AE549f1cC293930D4FEAd8aBb5bAd": 22,
      "0xd98C555186BB218B0fE0967c417CCE4Ef6bA6859": 23,
      "0x994E575563F8204D2CcC022e91dD91138B0506C2": 24,
      "0xd7E98780C10c9c716A87A7f76E76cB1d49556dA1": 25,
      "0xd7b70d726cb370Ac96EB6912Db1F57A8Df73267F": 26,
      "0x8356eDc1d60B0EdE626BC52980e708A604b0E861": 27,
      "0xbD1d593A038dA65F247C8b3d5C445dE9a031DB53": 28,
      "0xdaA4b508E1A958038f0f0b1F2EaC796A2FC17Bb8": 29,
      "0xA11E5D05295767FA83E8E7dB3b6adeA6bB67573a": 30,
      "0x094dDF6e715732c9c06b4ebd429D43733378cCE1": 31,
      "0xa2C57d3A66F72adf82cE655f87497DA623954918": 32,
      "0x5Ce2E1B0d987E17ceec95363bD2097855B1940c1": 35,
      "0xb1705734c69E41429E1dba3C0Ed72632265Ba6bF": 36,
      "0xa1186671046c7e19f1083b93b8e72c297e5Ba7f7": 37,
      "0x3FDEe45d0F9a055c175E6cB986AC07E5BAD9Befa": 38,
      "0x04159006f117Bc9777F5896B426956b9eE5A4540": 39,
      "0x73004b74ADaeEBdf163f212B77463380f6AD8c44": 40,
      "0xe55B34090743Da5A72d3762d28Fe6341fb335f73": 41
    },
    NineInchVault: "0x8878f27fD90922F4DeBb91dcf5B6E6301C23fE33",
    CakeVault: "0x0e173B11523feB064FD483209d0eD534831A2a86",
    NineInchFlexibleVault: "0x01956a14e265c32946E26e048657590902E621BE",
    CakeFlexibleVault: "0xADe258e63C88e815B49e7Ed345Bd007061a20D51",
    VotePower: "0xa83115B275abb50CA98E11d29984E923A3fCA21E",
    PLS_PP: "0x329958477eaa4BFC9A036BA620a7E71dE75d44D4",
    "9INCH_PP": "0x37515F5F2bb8a45Ae7CceF8653762a28cc8d4e3E",
    PLS_PLD: "0x330a3a0a05f8F3Cc7Bb1372e98EcaCa75fcbDc69",
    "9INCH_PLD": "0x0030176f066CE0929A31ea87697Dc51c715f8bEf",
    PLS_WHETH: "0x9F7578Fef8c1Fba986d2db83e4b1Fa5F1ECb7324",
    "9INCH_WHETH": "0x828cc8423fc97817CaD78C8bFBbA26923444dF60",
    PpVault: "0xFEF11BfA82A66e845Cb7EF815B83B2d22C324131",
    PpFlexibleVault: "0x88E26CbA18a160dA4585378c854ab114e08056E8",
    PldVault: "0x8A402a93469D5820079529CA093595e0d9AF62Ac",
    PldFlexibleVault: "0x53481fB1Bf5D13f60063085BC293b5998ED77ca0",
    WhethVault: "0xa1EAee97ee29e2C80ee9F1321E0132f19b45A26A",
    WhethFlexibleVault: "0x3eFC853438e9c06130D104088d73647517617887",
    PLS_PLSX: "0xB89ea01677529A8bbCECE3a486404880f9463C45",
    PlsxVault: "0x0Ea7f06D7694058B82d46Fb5c9281e1843Aa8702",
    PlsxFlexibleVault: "0xDF278aEa85F50c5Ae8513DC9c2C388E013408D24",
    SolidxVault: "0x0fE2E09d945429dC89cEdF2503234bf5931C9383",
    SolidxFlexibleVault: "0xb7A072Ac9d9fcF776ae1B1A12336f0789ED56216",
    HEX_eHEX: "0xF34157A1aeCfeEf326C1C33d90802354abc5a702",
    PLS_HOA: "0xF7bf31eed070538A5Ad491F7c0E57Bd717740575",
    "9INCH_HOA": "0xf3499Cd797367DB82C230D9AcBCB370f8d38D78A",
    "9INCH_HEX": "0x8948940e76eFc27B5e3253eA30aCc8e325a9cCa2",
    PLS_NOPE: "0xb823F30963511fb0a083073763f55d1285900C0c",
    "9INCH_NOPE": "0x633055AA25f33bbAe94091bbB753DD3b1849De0e",
    DCT: "0x54ef2C4c47bf6a1130b3d3D7980588F474D155aC",
    LNCH: "0xec8ac1aCCfbCD2d21bb6cB425532385aa831A71e",
    TNGBNY3: "0x2038EE7A83445a55B9B56Ee59dEE6279380bf8A1",
    VAG: "0x004FEC934D11D02C1734DDC72754C0ffE5e45ECC",
    CODE: "0x0C9778c243ea7A3ba70db1C338D132b7B48A194C",
    PAM: "0x6BB6fcda2ceB37fC468ae639E7f7426349abd7E1",
    SOC: "0xc1FdBe086dB2d1F1249aCec0F690E5BD64bfB5D0",
    SACK: "0xf293bF0BB4a972F7DF85422353739de2940c49A3",
    TEST: "0x74b786B3FAd3Df9EaE6c2655c2F7c78498BF37c3",
    PIZZA: "0x3e7ae951d9925E6e5DE6140a99B90c3259445c9B",
    KLD: "0x739f7B840F930366010202593058dae15c233A79",
    APC: "0xBb101431d43b0E1fc31f000bf96826794806e0b4",
    BART: "0xa89B728708Be04f57c7a33C6f790B6F077298e26",
    ALIEN: "0x1B7B541BeA3aF39292FCe08649e4C4e1BEE408a1",
    pTGC: "0x94534EeEe131840b1c0F61847c572228bdfDDE93",
    xMOONR: "0xF230d504887a750247490bf7A2C765D850A7e12E",
    MOONR: "0x2f28EC133bd2861a166d375145513EEd7F4947B4",
    MRS: "0x79e20C08e16416bF221EF4B17Bf25998C0305DaB",
    pDRIP: "0xeB2CEed77147893Ba8B250c796c2d4EF02a72B68",
    GOAT: "0xF5D0140B4d53c9476DC1488BC6d8597d7393f074",
    ROB: "0x1c2766F5949A4aA5d4cf0439067051135ffc1b28",
    DMND: "0xCF409C91B49DD3F796d20Eec20535fDC79A08798",
    JIZZ: "0x43C3b5fa74a0F97698530344670F75fbf5780040",
    ZKZX: "0x319e55Be473C77C35316651995C048a415219604",
    SPECKY: "0x1cB4f57F96527DCb57d445e7279811968c143ae9",
    DYOR: "0x5f02059710581523068aDfBfEBa89b77c22D3d25",
    SmartChefDCT: "0xA2B0a976A6CCE7241E4F67Ef47798C427c43Ec5b",
    SmartChefLNCH: "0xA010f8a18FB78cDdD125Caf1a3308457A75Ab679",
    SmartChefSER: "0xA31D12D9803105760b6F2d989b143Be732924176",
    SmartChefTNGBNY3: "0x2445088b45A3BC956C219cCE200ba83d32F5E8E0",
    SmartChefBSTRD: "0x65837A28826E96568f3e1081fC2E6c990735e0Ce",
    SmartChefVAG: "0x8dFbA4F0CD1bb57DA5a1c1717A43DE8C29a5D2BA",
    SmartChefCODE: "0x7D3Fd226153cd141eA2332785b3c8257c3BC27F5",
    SmartChefTF: "0x1cCDc4A491B68BF9E34A6B77B42079B290f6e774",
    SmartChefPAM: "0xaB39859fa4A603B76ad1f1629244a375Be9cf24a",
    SmartChefFLOP: "0x54ac4bE1F1d65df07BcFeB0f2d307F16C460f0CD",
    SmartChefSOC: "0x19cE54B80C25fed0c97D906F404c7A97719e734C",
    SmartChefSACK: "0x3Fd18785148963286390540C49Ee646F02910fA5",
    SmartChefTEST: "0x25De1928DaFc1C2C0e5d20Df38755d33bF5906ae",
    ONEINCH: "0x601717850846Ede2dE4aE0925Ae3432bc2B8A9E9",
    TWOINCH: "0x16bAd731aB12626861DeC30BE30C06134a9Cd073",
    THREEINCH: "0xdf1052160bEd2F3C29fAc0Dd668Ee72218b9d550",
    FOURINCH: "0x6545e05710e6d61C12115F8d37732BBD5037aECc",
    FIVEINCH: "0x367DC984491BeBEf09933764c44eCDe2393c351E",
    SIXINCH: "0x5fF56984b4E8Bc1659341f9A08eb9C98D21EcEE1",
    SEVENINCH: "0xfF47b80820EDef3A60eAdd8173E39aAF924b7Fa1",
    EIGHTINCH: "0xA73ce01727c74286C101e2b8116e0BB5c1777E7E",
    ZEROINCH: "0xFe1441d5fe0BD440b7F575F29737084DbBffE4aA",
    GDAY: "0x3981920A82d95A117A8747eCF9A11e105Ca38B62",
    WALES: "0x75336b7825c778F832F804d4e15EC4998EB35Ffe",
    ASS: "0x5b6E1763C402d9fc72CFe99fb3D1Fc01049DF2D1",
    NUTSACK: "0xf293bF0BB4a972F7DF85422353739de2940c49A3",
    LAUNCHPAD: "0xec8ac1aCCfbCD2d21bb6cB425532385aa831A71e",
    HELGO: "0x0567CA0dE35606E9C260CC2358404B11DE21DB44",
    FLOPCOIN: "0xA8B4e655d77aC9AE98521b53bB6b9b26a7F93c68",
    FISHY: "0x8C96A8D37c59F257f67BE0aa8884029622687909",
    FART: "0xF002Fff935c56bE365791f43Ce430929AeeC55db",
    YEP: "0xE08FC6Ce880D36a1167701028c0ae84dc3e82b8f",
    EX: "0x6c8A6C895729B7BD9ab5Df3A8A5435F65bc47C65",
    KIDNEY: "0x41C2e4F99B3241a275469da21f9cFa6A9aF1a025",
    WIF: "0x9e390aA6af3416B8415FA305473607841e3cb82F",
    GILF: "0xc9b43dD64c25b6c912814d9f38Dce55B7d513B9c",
    MYLPH: "0x1372A61e8F61663f31E80A2383faC29442C47b30",
    JUICE: "0xc596DF7F7E5Be7956dfEb97036b405a31F5C29Aa",
    DOOMER: "0xC3dDb5fbC71B9E4582b115596c42dbd168117085",
    CHUND: "0xCbC26F9c58e0194F5fB263cb2c0A71BA5f489455",
    SmartChefCHUND: "0xd4639E82AE5074fD6bbF4E3cb2B146B5A60DA1d6",
    SmartChefJUICE: "0x2A707d4B4BdBE3A46beC02238b6254DCE0dcE071",
    SmartChefDOOMER: "0x3E5B4c29C23C7e451CA6117dfDbF1aDAC07CCB1b",
    SmartChefJIZZ: "0x76b1d41916550b91cd2bF1Fb7B3C092AD74517Dd",
    SmartChef8INCH: "0x5F3004F16089219441C76Df973bc23F844a86085",
    SmartChefNUGT: "0x8bb970812b596257b59016eD188e7CAD50dF17F3",
    SmartChefCLOWN: "0x5FA7D6fdC28f0d34FD6cD7984d9C1dFB3338Da31",
    SmartChef3INCH: "0x5A05d5B48CDc5e88c7a18990be8b577961599Ef0",
    SmartChefEX: "0xf799FE6f591a9Da285B296A62deaF4A309fa2fE9",
    SmartChefYEP: "0x628CA91412538fe12CE373FB4BC5212022C43Ac8",
    pAAVE: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
    pLINK: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    PEPE: "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
    DADDY: "0x644E414F8E63116F05B7866112D41E7b075F16B7",
    KENNY: "0x36652357Df4485f9aB81758ed97CcDc2474A49bE",
    SmartChefDADDY: "0x59957CeFDdEcBa9B3CbE1A48CDC74957570235c1",
    SmartChef6INCH: "0x4b867FaFE7c5D4a28311a0B360023dD7d0b7D591",
    SmartChefKENNY: "0x4fceDa3114ece4045dE2284f22238c1480bc67AB",
    LUNCH: "0x1F5E09CA8164d33d3923ec89c76620B15c51C25B",
    MIGUEL: "0x7fb58eA2FE85a2Ee5E092DCB4dCE2c8E695f4a91",
    SOLIDX: "0x8Da17Db850315A34532108f0f5458fc0401525f6",
    PI: "0x9F9499D15c1399A7eC95A1980A544D088FABaD6b",
    ATROPA: "0xCc78A0acDF847A2C1714D2A925bB4477df5d48a6",
    AXIS: "0x8BDB63033b02C15f113De51EA1C3a96Af9e8ecb5",
    PLS_ATROPA: "0xd7E98780C10c9c716A87A7f76E76cB1d49556dA1",
    pDAI_pWBTC: "0xd7b70d726cb370Ac96EB6912Db1F57A8Df73267F",
    pDAI_pBTC: "0xd7b70d726cb370Ac96EB6912Db1F57A8Df73267F",
    PLS_pDAI: "0x8356eDc1d60B0EdE626BC52980e708A604b0E861",
    PLS_pWBTC: "0xbD1d593A038dA65F247C8b3d5C445dE9a031DB53",
    PLS_pBTC: "0xbD1d593A038dA65F247C8b3d5C445dE9a031DB53",
    PLS_AXIS: "0xdaA4b508E1A958038f0f0b1F2EaC796A2FC17Bb8",
    BBC_AXIS: "0xA11E5D05295767FA83E8E7dB3b6adeA6bB67573a",
    BBC_INC: "0x13f31A66a0F844d0bcfE1832A3C7096dd4BDE79E",
    BBC_SOLIDX: "0x8e680888C29AE549f1cC293930D4FEAd8aBb5bAd",
    "9INCH_SOLIDX": "0xd98C555186BB218B0fE0967c417CCE4Ef6bA6859",
    PLS_BEAR: "0x994E575563F8204D2CcC022e91dD91138B0506C2",
    PLS_1UBC: "0x9796eb9F9267A433C1f608D4c5106FCF5c31EF78",
    PLS_LEWIS: "0x5D3ec51CAc9f59cc585676Fb5d1a4D5eC816b5F0",
    PLS_HUEY: "0xdBdcC4045b2e1d850a945a08532fD5f083C733a4",
    HOC: "0xd22E78C22D7E77229d60cc9fC57b0E294F54488E",
    FIRE: "0xf330cb1d41052dbC74D3325376Cb82E99454e501",
    SOYMMI: "0x1403C9BbaEF967d54a0106B305C59380414E1c5f",
    ICARUS: "0x8c4a50c87e348f602ac6a59f4Da857ee23307a42",
    LEWIS: "0x3422D51099f1FFE70eC4Cf1F27196D5946aFF52a",
    HUEY: "0x49544b466DBf6799C68d2C87f9F7A39f98B6A08C",
    ONEUBC: "0x798284FB86a512D49e2bB05638306214Ab1A6213",
    SmartChef1UBC: "0xc6d79D860843dbFe695E71B673EE9a54D5070205",
    SmartChefLEWIS: "0xb52Aa536B28B78CaA8B631a0254133Db97F1C7cf",
    SmartChefHUEY: "0x9d1dC77AF58a0de78d7c434ccEE028050Ea84C1D",
    SmartChefNOPE: "0x7FCf320B084e0755980d50A11FF7bA8A7fe9Bd0B",
    PLS_FIRE: "0x094dDF6e715732c9c06b4ebd429D43733378cCE1",
    "9INCH_FIRE": "0xa2C57d3A66F72adf82cE655f87497DA623954918",
    MUNCHIES: "0xB0D7c3EdF6769538a08208Cc6AaddCb1C6143a81",
    BRO: "0x401464296A7e0cd14D85AB6baF0Dc91B5ad5Ad1b",
    HARD: "0xB6Bad00525221EB28Eb911a0B1162a0709b4CE57",
    SmartChefBRO: "0x0ADE4ec7f9a6ff78D222ac438D8895728F02FC79",
    TEVE: "0x5FE90464eD06B809D3f8888aD12e98346E409d4D",
    PRO: "0xaD35591635c421551d49eAd8bCe9FFd55B60a322",
    BEER404: "0x0760F66ed7139aaa70Dc21c8f9E75167e295D4Ab",
    OCEANU: "0x82E6Fd2c7B5488DDb0ca4fe0b2D37741BCc8C767",
    PYSD: "0x3BF4DdA59C19220e439bc58F5b982647bad8b8e9",
    M3M3: "0x78a2809e8e2ef8e07429559f15703Ee20E885588",
    BUZZBALL: "0x5D9cDb31C973847B1C3b13dab0b6E78BfD0cDA49",
    BTC8: "0xD4BB404e36a0bf7Dc9E8e38Dc2F166F0Efad724e",
    BMW: "0x82D394e2d5354fdb92f52208E721471FB9415ab1",
    ONEDOLLAR: "0x7a8723500ABb21BAF736EBF5BcC8e7a02B0E3906",
    R0D0: "0x927AB78572C2340E8013417C826A8DeaEA535da8",
    PLS_BRO: "0x5Ce2E1B0d987E17ceec95363bD2097855B1940c1",
    BBC_BRO: "0xb1705734c69E41429E1dba3C0Ed72632265Ba6bF",
    HARK: "0xCD2854239FEde3B392061397C1f9F3446d605a82",
    LTCG: "0x1C2206cD188ccF3FdfA29001f936ef6f3e8decf9",
    SmartChefSOLIDX: "0x9C792D7CA063BEf9E0326C52bfF062315e6cBBB2",
    KNOBBY: "0x9E07269DCD792AA3651aA4F62dd3eeb2e301C21f",
    MATI: "0xC697E0bB8ac6D1CFd928bca3E43CC64e2190e828",
    BFLAP: "0x0C6197cAfC2b349ca0663b09A2baCd5E279a3797",
    BAANA: "0x637Ecd6b33BD8d5A550939A2e6058Dd7Dc52812e",
    PNUTBUT: "0xD9e265B409BD8eb924cc97A0aA8C29765cf9E581",
    TWOPHUX: "0x115f3Fa979a936167f9D208a7B7c4d85081e84BD",
    Prime2PHUX: "0x6d358129Df7Ae10ee925D52e16F71A76D424990B",
    XXL: "0x302BC44e26ccBCfdFe6Bf903cD306e1aB182c7CC",
    SmartChefTEVE: "0x1F69E49f29F8a50eAcD0AB944B6be2A2C9Ba8555",
    SmartChefKNOBBY: "0xA40FE7a5a73b5c90Db2c887f0075Df4F32dd0657",
    SmartChefXXL: "0xa7edB55F508fFC5f7AB72bED04c886589Cb333aF",
    sBULL: "0xA48A31A820Db2294c52d0F9ED2Af918FD07abc1d",
    SmartChefBFLAP: "0xd613d201785B2ab010846f9f9786CF11212C101F",
    NOBULL: "0xCBC7f61686D8ceb8160cBff9A5Ef8e1409cF4ddF",
    MCOP: "0x97E2BBD2A8EB2b8fe120B77b4849419e448562B9",
    "SmartChefBEER404.wrong": "0x1893341368A8D2117fC8929eefC4a5ebc7Ba7662",
    SmartChefBEER404: "0x176D07bad4af3c279EC79279a1Be02a5aC860149",
    SmartChefCHAD: "0x4711b5BB5235bfEcA2b22996c5e12bEd98235BA9",
    STACK: "0x67d8954C2B7386c8Dbf6936Cc2355bA2227F0a8f",
    SIMP: "0xdaca90aB4F1e776915B48cDfc70a043EdE0Dca83",
    CHAD: "0xCB43bc226386f8b9d63879ee3A4C5b32E6516054",
    FREE: "0xde3bab90cA73D22C77050153f0bDab4c516C5552",
    BEAN: "0xd7407BD3E6aD1BAAE0ba9eaFD1Ec41bFE63907B2",
    MAFIA: "0xc305094605d26380190482EE639193EC1301575E",
    BRKFST: "0xD8F37B7A8207C58e3AeAD1c48f11F2Dd30BDAaeB",
    DINNER: "0x24cC226bDa64734C8cCB15C56B0af4dfE61Db63f",
    MCR369: "0x7FF1C0e8C968a8Ddc1F25e3D891562EA549Ee32e",
    SHKR: "0x97F50E6bAAA852701D2fb9d7542C87ac7cD8262D",
    SOIL: "0x22b2f187E6EE1f9Bc8f7Fc38bB0D9357462800e4",
    STBL: "0xae1d4cde040524321c87b923636e5ad564147225",
    SOULS: "0x3b5ebc98ed7a796a60eda15b54cb084629134573",
    KINDS: "0x84c89c8b80c78fb46f1eacf1a75cdc5e0b4a2847",
    ALI: "0x828f3c8e22cb8ccfe5ff2de59680096959a2571c",
    CAMEL: "0xC1967c5a0cedc577D9017D2465bC0b0c55eAFE49",
    WRECKED: "0xD29582F42C6Aa7Bf4133F0CEda65de9c5fBEd338",
    KING2: "0x3653FFa4c863585F8303BD649BcF934C621EE30C",
    VETS: "0x4013abBf94A745EfA7cc848989Ee83424A770060",
    DRAGON: "0x3731031CDBaE9292A26338A6498d0b379416B819",
    RETARDAI: "0xd817ceAe82d5204230D54f9C634f7B81690290B1",
    SOTASODA: "0xD1D5A10d702d98647C5F06ff057Bbea78818d27C",
    MNY: "0x8a661f1070Da8C2BfED98ba6B2D24D2570c8Ffd7",
    KAM: "0xD53DbE688a9a4Fd1227dCEF6d40506E718C6C4b2",
    SQUISHYX: "0xA059C4416D2689C75361B8fDFc23d0e066edd06b",
    Aunty: "0x51a05d2df463540c2176bADdFA946fAA0A3B5dC6",
    HATCHI: "0xAf5ec50cECCc4acb41204525019F531B2A55675c",
    PLS_SOLIDX: "0xa1186671046c7e19f1083b93b8e72c297e5Ba7f7",
    NINE: "0xc07b238A96306C64A4C3852751f66a4Cd2cabbA8",
    GENGAR: "0x8e60Dc40D25565867da4e0cD9c55411043F14c19",
    Electric: "0x556022edbC20e58e2158b4db810B93956bb9d0C2",
    G: "0x81e6b547233dae955BDe203fBa478e6C3803f8bf",
    SmartChefElectric: "0x21A78255B14CF3F4202b6d5E96Ac560496Bb3f30",
    SmartChefG: "0x3B93a311429cDff55F0db7cf2B6B34E276A72a98",
    SmartChefSOTASODA: "0x3191A276c226151Bbb62b746029727Cc2f97b13B",
    SmartChef1inch: "0x8EF259Ea9bd2eC18c237c5db47C612B4b6e807fe",
    SmartChefCAMEL: "0xe26dd11812B3285aa184688bAd054Ca1176f353a",
    SHOOS: "0xD010BF67fC59AA9e440125990de23AAb91B2e5b1",
    Stack: "0x67d8954C2B7386c8Dbf6936Cc2355bA2227F0a8f",
    X: "0xA6C4790cc7Aa22CA27327Cb83276F2aBD687B55b",
    SAVVA: "0xb528a9DB27A74dB802C74D0CCc40657efE5F0A45",
    PLP: "0xb31cA779511Ffb3546aeCCcaB0133AC091285F9f",
    FREE$: "0x23f2621d0C392d1dD4E2840416d651536dA64D4A",
    NUTS: "0x874e3F27140FCB7cd734df9758552f36f1AC5fcF",
    MOG: "0x495d9b70480A22a82D0FB81981480764BA55550e",
    DAI_pDAI: "0x3FDEe45d0F9a055c175E6cB986AC07E5BAD9Befa",
    PLS_MOG: "0x04159006f117Bc9777F5896B426956b9eE5A4540",
    PLS_PEPE: "0x73004b74ADaeEBdf163f212B77463380f6AD8c44",
    ALIVE: "0xB0eBAf9378d6E7531ba09403A12636947CC2f84b",
    PAXG: "0x45804880De22913dAFE09f4980848ECE6EcbAf78",
    PLS_PAXG: "0xe55B34090743Da5A72d3762d28Fe6341fb335f73"
  },
  "943": {
    WETH: "0x70499adEBB11Efd915E3b69E700c331778628707",
    USDC: "0x0Ff625db5A78F260c91C928Ad177e71565700c7F",
    USDT: "0x0Becabdf71037848BCf96a4923A1764710e71E6A",
    DAI: "0x52d0B1743CAc29BaB836EfC6E8f614AcFC9aDe19",
    HEX: "0xc2cc7ADD3885DB3DcF855dd6f2C0CdF2a11AB734",
    PP: "0xF68A95926c7b753B7E8e5635830523363d903881",
    PLD: "0x68a3EE5C3cAF5483bD2B8d086403B41D56C31F3d",
    PLSX: "0x0c21104C52a1A76c1D74ac5F2734121341eb426a",
    NineInch: "0x29e3dbE974487f6a68ad4B4dab14c16B64Cea795",
    BBC: "0xCFa1b5700bdf22f0915317535baa4de7dC05B60e",
    NineInchFactory: "0x466A0929c9E2D2dc7A0c93f7AdAF805Bf30e727A",
    INIT_CODE_HASH: "0xd2cf61d4acad30e9fe5ec59d0f94de554d88701f1bd8fc635546866716718511",
    NineInchRouter: "0x99c2d4937756Cf66D04f7db362B87604f4303969",
    PLS_DAI: "0xB4aC1401babd831FBD8a5c9382E7df0C3A6cc5B5",
    "9INCH_BBC": "0xeB00c6a86D85EE5f7aBa7ad608f84c95a9bC9F07",
    "9INCH_PLS": "0xc31D3Dc90089F2dE953c40971789F68e766063Fd",
    "9INCH_PLSX": "0xf9D2bE468B38687Ba37413ec39c47A973051ab86",
    "9INCH_DAI": "0xbBafA217Ca35E95Ba167aF3869F74645a75c0Ae4",
    "9INCH_USDC": "0x9071d2F7f28c90003f148F362dBe7792A5458896",
    "9INCH_USDT": "0xE1Ac6219cbA7AD53A3cFfef09646185a6Bd939dd",
    OrderBook: "0x18C5F180921022731179F08E11188d7b40B7298b",
    MasterChef: "0x77B6d0BeA45844CAD3E619b6d0fF95c0077Fd7ff",
    NineInchBuyAndBurn: "0x0180c317B613f1958Ac1BCCBdB0e32F250307F80",
    NineInchDCA: "0x50F388E6693a795636Cd9371907D8bC9048bFF05",
    Pools: {
      "0xc31D3Dc90089F2dE953c40971789F68e766063Fd": 1,
      "0xbBafA217Ca35E95Ba167aF3869F74645a75c0Ae4": 2,
      "0x9071d2F7f28c90003f148F362dBe7792A5458896": 3,
      "0xE1Ac6219cbA7AD53A3cFfef09646185a6Bd939dd": 4,
      "0xeB00c6a86D85EE5f7aBa7ad608f84c95a9bC9F07": 5,
      "0xA3ee915aD350C2836f50994d11fFdd5888D52753": 6,
      "0x9DE664b67e75c98e91caf33AFA94D35dB60460d1": 7,
      "0x733768a433eCB6dff364D9E52b50ea75d371f57a": 8,
      "0x95D52744346582aCaDAa407B6075adE3B9dB1a39": 9,
      "0x1C07F4b4D528F0029918DE0CD7cdBA9aa280bb03": 10,
      "0xf9D2bE468B38687Ba37413ec39c47A973051ab86": 11,
      "0xffcBf6b87ce771917D9DE00606110E9534912c96": 12,
      "0x3a94Ee5b4CEf0C074b6c341Ff7cf5C3cCd2200fE": 13
    },
    NineInchVault: "0x97e06E250a407D69414F06871D7CD955b1e5Be6e",
    NineInchFlexibleVault: "0xaB32D72667454df6FBbCAA10764faa2a830304a1",
    VotePower: "0xb450a192D5Fa76A3E3df44595B81c9629E80CC25",
    CakeVault: "0xDDdEcaf81D7306b527d1db15C6FF744962b6e7b6",
    CakeFlexibleVault: "0x0Ed556933DE97E183931A50b5DB1A0FD8cC2ca0C",
    PLS_PP: "0xA3ee915aD350C2836f50994d11fFdd5888D52753",
    "9INCH_PP": "0x9DE664b67e75c98e91caf33AFA94D35dB60460d1",
    PLS_PLD: "0x733768a433eCB6dff364D9E52b50ea75d371f57a",
    "9INCH_PLD": "0x95D52744346582aCaDAa407B6075adE3B9dB1a39",
    "9INCH_HEX": "0x1C07F4b4D528F0029918DE0CD7cdBA9aa280bb03",
    PLS_HEX: "0xffcBf6b87ce771917D9DE00606110E9534912c96",
    PLS_PLSX: "0x3a94Ee5b4CEf0C074b6c341Ff7cf5C3cCd2200fE",
    PpVault: "0x0B594A9936756189e25C8eC195a664f02d3985b3",
    PpFlexibleVault: "0xC7af83617ceCefD8bFe98bf9304668AFA1876A35",
    PldVault: "0x5e95Aca8c4eEf37D4dF1A3ed6e98daC052fEdDDa",
    PldFlexibleVault: "0x3dB6F972AF405e6b2797D55879e21D3405ad3c6d",
    HexVault: "0xc48cfB926D5C0FD0d3af1659D517D2ae3CF9fE30",
    HexFlexibleVault: "0x23fC9BbE28D3f718c72Efdd67CB3Ac446f8E2e08",
    PlsxVault: "0xcB3c5375a6916b5806bdC95720a3CD66316c2db2",
    PlsxFlexibleVault: "0x35a7B05Bb393BcAc818B5fc612e651bA95b449d1",
    SmartChefPP: "0x9cf74F7A3f50C73E67501a4fDb40F78141B9d07b",
    SmartChefPLSX: "0x3F495C0AB1Fd6367B77586330A30B866bcaFc3a7"
  },
  "31337": {
    NineInch: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0",
    BBC: "0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82",
    X: "0x9A676e781A523b5d0C0e43731313A708CB607508",
    PoolToken: "0x0B306BF915C4d645ff596e518fAf3F9669b97016",
    USDC: "0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1",
    DAI: "0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE",
    WETH: "0x68B1D87F95878fE05B998F19b66F4baba5De1aed",
    Multicall3: "0xd9140951d8aE6E5F625a02F5908535e16e3af964",
    NineInchFactory: "0xc6e7DF5E7b4f2A278906862b61205850344D4e7d",
    INIT_CODE_HASH: "0x8cf8d9330e4ddecb109b3334d73a8b95e826c84fdbc40fc5da250ba8a253c53f",
    NineInchRouter: "0x59b670e9fA9D0A427751Af201D676719a970857b",
    USDC_WETH: "0x92BFac755d7bFCcD532F1E79eea18Cb7d65622aC",
    ETH_USDC: "0x92BFac755d7bFCcD532F1E79eea18Cb7d65622aC",
    ETH_9INCH: "0x03dF99f3FDD410e471fdCefC5eE6E6d8CA5D6421",
    ETH_BBC: "0xF2b7FED706ce9935b0b50E1059529F4b861B08c2",
    ETH_X: "0x4c56b5d4a4Ed76c32366B0E806Efd8A47FBd97e9",
    BBC_9INCH: "0x187Fbe23AaaFe30d1AC8Eb646761090dddb50C9C",
    ETH_HEX: "0xCaB92a4e8D87661d389c5B91eD3CA3Ac168581EE",
    HEX: "0xc3e53F4d16Ae77Db1c982e75a937B9f60FE63690",
    ETH_DBI: "0x96aD3738E47bAfB4b2Fe2548c403b9B5A4c987F9",
    DBI: "0xa82fF9aFd8f496c3d6ac40E2a0F282E47488CFc9",
    ETH_PP: "0x4dae80A3D07e054c0f466688e4f6e6fcAA3114Ae",
    PP: "0xf5059a5D33d5853360D16C683c16e67980206f36",
    ETH_PLD: "0x064331B1CcCb5EC9Fe7e69B76E9F5d08a20d0562",
    PLD: "0x70e0bA845a1A0F2DA3359C97E0285013525FFC49",
    ETH_HDRN: "0x1f324063665a5261Ed484B6Db4A012a0B0c7E1dE",
    HDRN: "0x0E801D84Fa97b50751Dbf25036d067dCf18858bF",
    ETH_XEN: "0x4b63659f9ADFf79612D9D4378DB723D77FB8E4b6",
    XEN: "0x5eb3Bc0a489C5A8288765d2336659EbCA68FCd00",
    ETH_AIINU: "0xBd058210e4FC6EF92A1d7ef0eCD1a18b9980A3fe",
    AIINU: "0x4c5859f0F772848b2D91F1D83E2Fe57935348029",
    ETH_MORE: "0x602141fF843C02f9ba0911be5AEceCA6186b31a2",
    MORE: "0xb7278A61aa25c888815aFC32Ad3cC52fF24fE575",
    ETH_PLSD: "0x1caE0B2037183Cd712c22152F72C876109224A8d",
    PLSD: "0x2bdCC0de6bE1f7D2ee689a0342D76F52E8EFABa3",
    ETH_PEPE: "0xfEECF5b3BEF30E1138c05Dd0120C774b57F3b816",
    PEPE: "0xc351628EB244ec633d5f21fBD6621e1a683B1181",
    ETH_MAXI: "0x6f557E50B9aC289489B9441E860b949Fe46E6B35",
    MAXI: "0x1429859428C0aBc9C2C47C8Ee9FBaf82cFA0F20f",
    ETH_WAIT: "0x2A31Ef7e6E1e26Ec237800D6bc796081034d8f07",
    WAIT: "0x922D6956C99E12DFeB3224DEA977D0939758A1Fe",
    ETH_BNBP: "0x052F5b0C217F997CCA1D4C069932088b92eDCEEE",
    BNBP: "0xdbC43Ba45381e02825b14322cDdd15eC4B3164E6",
    ETH_ZEUS: "0x7973EAB9BE71496FbB9AA52d35e5465EeD4DC212",
    ZEUS: "0x21dF544947ba3E8b3c32561399E88B52Dc8b2823",
    ETH_ePhiat: "0x2eC0aaEBBE418885216B3Bc0F965820A60401Db7",
    ePhiat: "0xDC11f7E700A4c898AE5CAddB1082cFfa76512aDD",
    ETH_DXN: "0x0Dcfc7F5255E323ea59c0E3e6dD5bCf4564Ae7c3",
    DXN: "0x8198f5d8F8CfFE8f9C413d98a0A55aEB8ab9FbB7",
    ETH_XLON: "0xD803121A36973d896916ba7fF54E84DA597dcEA2",
    XLON: "0xf4B146FbA71F41E0592668ffbF264F1D186b2Ca8",
    ETH_TEXAN: "0xf98B843c095bDD237C6A51280e5184AcE0FB898B",
    TEXAN: "0xBEc49fA140aCaA83533fB00A2BB19bDdd0290f25",
    ETH_ICSA: "0x9c2Cf39d10d64371f3D22dA2B2c09fB77cf23D86",
    ICSA: "0xfbC22278A96299D91d41C453234d97b4F5Eb9B2d",
    ETH_TEAM: "0x30BAE69e8a6D26b207A3B857e96e4BC0c79bb21b",
    TEAM: "0x1c85638e118b37167e9298c2268758e058DdfDA0",
    ETH_DECI: "0x606471ec630bA276F3c2ecC04D39477A498ddf80",
    DECI: "0x7A9Ec1d04904907De0ED7b6839CcdD59c3716AC9",
    ETH_LUCKY: "0x664606b13aDb7E84Ffb075Bb178F9DFA8802b12C",
    LUCKY: "0x86A2EE8FAf9A840F7a2c64CA3d51209F9A02081D",
    ETH_TRIO: "0x89a18f2e6BdeF8D7dC84c6Ee95779591C9C86781",
    TRIO: "0xAA292E8611aDF267e563f334Ee42320aC96D0463",
    ETH_POLY: "0xD755c3D703350ca5d19316d0D452ec695870b45A",
    POLY: "0xe8D2A1E88c91DCd5433208d4152Cc4F399a7e91d",
    ETH_BASE: "0x71bC759008466858390ce125dd47461F72120F8f",
    BASE: "0x4b6aB5F819A515382B0dEB6935D793817bB4af28",
    ETH_PLSB: "0x9a0eddE73AAFe2b07b54AcdD299d24aDaDAe2203",
    PLSB: "0xF8e31cb472bc70500f08Cd84917E5A1912Ec8397",
    ETH_ASIC: "0x64F97bdc015C2FAad438AE2EFd543a8b7041D088",
    ASIC: "0x34B40BA116d5Dec75548a9e9A8f15411461E8c70",
    ETH_IM: "0x0658ad2B5c3022d7f063931143c5b25b50119B83",
    IM: "0x22753E4264FDDc6181dc7cce468904A80a363E44",
    ETH_PZEN: "0xe3C1f29edc404762cce0a757A265de138b576d8f",
    PZEN: "0x276C216D241856199A83bf27b2286659e5b877D3",
    ETH_COM: "0xe5f2B5a44AE5c4ACb276329C669F0F0c84F916Dc",
    COM: "0x5bf5b11053e734690269C6B9D438F8C9d48F528A",
    MasterChef: "0xab16A69A5a8c12C732e0DEFF4BE56A70bb64c926",
    Pools: {
      "0x187Fbe23AaaFe30d1AC8Eb646761090dddb50C9C": 1,
      "0x03dF99f3FDD410e471fdCefC5eE6E6d8CA5D6421": 2,
      "0xF2b7FED706ce9935b0b50E1059529F4b861B08c2": 3,
      "0xCaB92a4e8D87661d389c5B91eD3CA3Ac168581EE": 4,
      "0x96aD3738E47bAfB4b2Fe2548c403b9B5A4c987F9": 5,
      "0x4dae80A3D07e054c0f466688e4f6e6fcAA3114Ae": 6,
      "0x064331B1CcCb5EC9Fe7e69B76E9F5d08a20d0562": 7,
      "0x1f324063665a5261Ed484B6Db4A012a0B0c7E1dE": 8,
      "0x4b63659f9ADFf79612D9D4378DB723D77FB8E4b6": 9,
      "0x602141fF843C02f9ba0911be5AEceCA6186b31a2": 10,
      "0x1caE0B2037183Cd712c22152F72C876109224A8d": 11,
      "0x0658ad2B5c3022d7f063931143c5b25b50119B83": 12
    },
    CakeVault: "0x6F6f570F45833E249e27022648a26F4076F48f78",
    CakeFlexibleVault: "0xCA8c8688914e0F7096c920146cd0Ad85cD7Ae8b9",
    NineInchVault: "0x19cEcCd6942ad38562Ee10bAfd44776ceB67e923",
    NineInchFlexibleVault: "0xD42912755319665397FF090fBB63B1a31aE87Cee",
    HexVault: "0x01c1DeF3b91672704716159C9041Aeca392DdFfb",
    HexFlexibleVault: "0x02b0B4EFd909240FCB2Eb5FAe060dC60D112E3a4",
    DbiVault: "0xa6e99A4ED7498b3cdDCBB61a6A607a4925Faa1B7",
    DbiFlexibleVault: "0x5302E909d1e93e30F05B5D6Eea766363D14F9892",
    PpVault: "0x96F3Ce39Ad2BfDCf92C0F6E2C2CAbF83874660Fc",
    PpFlexibleVault: "0x986aaa537b8cc170761FDAC6aC4fc7F9d8a20A8C",
    PldVault: "0xD49a0e9A4CD5979aE36840f542D2d7f02C4817Be",
    PldFlexibleVault: "0xe1Fd27F4390DcBE165f4D60DBF821e4B9Bb02dEd",
    MoreVault: "0x66F625B8c4c635af8b74ECe2d7eD0D58b4af3C3d",
    MoreFlexibleVault: "0x8bCe54ff8aB45CB075b044AE117b8fD91F9351aB",
    PlsdVault: "0x70bDA08DBe07363968e9EE53d899dFE48560605B",
    PlsdFlexibleVault: "0x26B862f640357268Bd2d9E95bc81553a2Aa81D7E",
    PepeVault: "0xB06c856C8eaBd1d8321b687E188204C1018BC4E5",
    PepeFlexibleVault: "0xaB7B4c595d3cE8C85e16DA86630f2fc223B05057",
    ImVault: "0x413b1AfCa96a3df5A686d8BFBF93d30688a7f7D9",
    ImFlexibleVault: "0x02df3a3F960393F5B349E40A599FEda91a7cc1A7",
    MaxiVault: "0x71089Ba41e478702e1904692385Be3972B2cBf9e",
    MaxiFlexibleVault: "0x8F4ec854Dd12F1fe79500a1f53D0cbB30f9b6134",
    NineInchBuyAndBurn: "0x54B8d8E2455946f2A5B8982283f2359812e815ce"
  },
  "80001": {
    WETH: "0x1f17E4a8f3332dcA697812ad232d3923c4442DCA",
    USDC: "0x95cBbF1FfFD16b2c4574bed4BECc81510fD057fd",
    NineInch: "0x1385D8B0cb0375F1E9896825bC0791B0A2e2A95f",
    BBC: "0x6C2b4E09466761657e9C1E3865e0406a09C1a5c5",
    HDRN: "0x789ae61512DfECD741B41AE583CED8DF1B5BE24F",
    TEXAN: "0x4bD4687e7cCc56C62A5a2AeC1a201b32492D1b04",
    ICSA: "0x30CC22681166c0207e2fce48E9ecFD604C6b059C",
    LUCKY: "0xBc8815d5E1ce642e6f6a47D64421cC37bb5D595c",
    POLY: "0x3956D5304216A718A6f73a0449564a6a832C12Bf",
    PLSD: "0x1c3F47d0f8B7098e548aDce6Ecf5e39b14350585",
    PLSB: "0x37C0395d627f1ff8C729Eb7723282e65402FFFf9",
    PP: "0x4D321e44e57bEd781f96936413BBcD54c8f5d255",
    MORE: "0x32cae85d0bA8f7a157b3B806c4873B37f1F86944",
    PLD: "0x755392eDDA7be30a4cCd5fa7844eD4b071512735",
    PZEN: "0x1Da62928Bf9870c898F2A15Cd9c6BFc261558605",
    COM: "0x73762D81d0Eb5c1A502f2b9F974FAeB1691Fdf56",
    DBI: "0xC96e8301CA14418C36f4cd17bcAc13F4977520aC",
    XEN: "0x74dFF5d784eF352bDEbCDe68E9B1923395725dA6",
    HEX: "0x4f77d5060D84D5245E74D7601e4D980aF9cCc75d",
    aPhiat: "0x2eebA97928d87efA50DAc8800E8a25801367cCA8",
    DXN: "0x54C415f887905504347c3340564D1984F96b59F7",
    XLON: "0x6c0325fe027D20eeCa49dbBaa4692Fca8f835b7A",
    NineInchFactory: "0x3F264Fa26C702D60345a6F40DCbA1229956ACa1a",
    NineInchRouter: "0x1Be6b96d91c72Bdca7a128168e5a05F8FC46C941",
    INIT_CODE_HASH: "0xc1c961cce10f45d5679a9f9afc98217c6cb990b024ae51dfb749f47bfb3d48e0",
    USDC_WETH: "0x439b2D46571fCE8F8646CcF211Ff4e846fBE1068",
    BBC_9INCH: "0xB0AC984832ee96F953cafb0B786D37e8249b8f3F",
    ETH_9INCH: "0xb839528AC4e14786a1D43124ED94efE9BcB0a37F",
    ETH_BBC: "0xcF666d07E1C04810ED17c4cf0d1002F91872D407",
    ETH_HEX: "0xC00F6B4E389A3FF21FD8b7b495064c78f4cebD36",
    ETH_DBI: "0xe76753Dd0E849c70040d6E03877fEc34165ad25d",
    ETH_PP: "0xC5703Ea2308dDc4566a8718Fb67D7b122527E470",
    ETH_PLD: "0x7E022238dAB58f8865D362D5c0DB84e96f8b9b67",
    ETH_HDRN: "0x17E9263c95c2B924D43a474fA8F0dADAd2D476Db",
    ETH_XEN: "0x048dbA18b4d13e6bE9d7414313bFa5cD8664E538",
    MasterChef: "0xe63a0e573751eb90f0d6E7633cfa7A494c319317",
    CakeVault: "0xe7f02D3083D5b3fE10958099fa83aC1d25d67229",
    CakeFlexibleVault: "0xECa014Ea12179829F3e8cDdc16C55444E7f7b9dD",
    SmartChef: "0x44bfdE0F8D213da9be2c2e3c96121E0975EA3577",
    NineInchBuyAndBurn: "0x1C868D136b8b412A662eBbb6119066E919a9327B"
  },
  "11155111": {
    LINK: "0x779877A7B0D9E8603169DdbD7836e478b4624789",
    USDC: "0xFd3E13ef5368c25A26C4F54d3080E0a1057612a9",
    USDT: "0xBb620AcB93281F700E61AE19F99913B2e79A04B1",
    DAI: "0x51B9084BD85725D725608aC712dDc99894De965D",
    HEX: "0xc11E22C20E340c606E9A22286ae0C7597B64D7EC",
    PP: "0xe2AF2E225B04832fa4e82DB74D6aa8C36Ff8c073",
    PLD: "0xE488aaBac30022C338edf533C4688e284391a088",
    WETH: "0xE1818e1FBcC010c8eA1c9Aa4cAeB42b1030D7273",
    NineInch: "0xfE8a8308E0d26E85f9593a443Bb61857f105C0B3",
    BBC: "0xFb9a3B6e7F16977A2dD2aC239D74E5C74275B50D",
    NineInchFactory: "0x6f69cb05EE1480731c94f5083b1086BD224cc3B9",
    INIT_CODE_HASH: "0xd2cf61d4acad30e9fe5ec59d0f94de554d88701f1bd8fc635546866716718511",
    NineInchRouter: "0xDf247000F750AE87CE8C1a5E2c059592C9D0B09e",
    "9INCH_ETH": "0xad6E4B75f6447a4220767cf1D9852a4db5121030",
    ETH_DAI: "0x85D3B119390Ed70e651D39E0751778AA590164e2",
    "9INCH_BBC": "0x03490f33629114Efd3e8e62dcBd53E818891855d",
    "9INCH_DAI": "0x101A5F8D7b60606834a4aA21E2754469Dd3F2fb1",
    "9INCH_USDC": "0xAe4E5AEfee213416C94D886594bC25C8c12D1BdD",
    "9INCH_USDT": "0x1B03f4ED224e2577036933B1AdF50068924Ed674",
    "9INCH_LINK": "0xb0E46AA0FB3704905dEa29E03a184Ff8674070F3",
    MasterChef: "0xeBE381E93980BeDc0bbBdCf6A3F33ee1f0dcB262",
    OrderBook: "0xD3A1Cb828231c6c2d2dB5822a458DB212b3e5d0F",
    Pools: {
      "0xad6E4B75f6447a4220767cf1D9852a4db5121030": 1,
      "0x101A5F8D7b60606834a4aA21E2754469Dd3F2fb1": 2,
      "0xAe4E5AEfee213416C94D886594bC25C8c12D1BdD": 3,
      "0x1B03f4ED224e2577036933B1AdF50068924Ed674": 4,
      "0x03490f33629114Efd3e8e62dcBd53E818891855d": 5,
      "0x9AA2F6293299DaF2b2711cE514aDbE29400DF885": 6,
      "0x596c43f172bE1Ed5d39Fb1888AE1Cf08Fe18c4C0": 7,
      "0x5ad7315c89A023f02e33FA65b783EE8C2AfBBcc0": 8,
      "0x52d20Ab642d6f20Df955a47566CF40259cced897": 9,
      "0xC44CF33160EE4270C48310970337685189117901": 10,
      "0xE2623a1c7CDBAdC86777b015217F66cDfe8DabDF": 11
    },
    NineInchVault: "0x0E6deb073832cF14b5F4fAed5642e706603DC446",
    NineInchFlexibleVault: "0x31B2901c088Fa1Bc928Cf53df80447be1e8fD3F2",
    VotePower: "0xCf7Ee3668f69fF0711A5d747508659d1a8b85F72",
    CakeVault: "0x4e21b1fD5A5A49F6b201846540f21c17789831A8",
    CakeFlexibleVault: "0xcCCB461C1301d2bC1e03bb351286AE0F8a9a81E7",
    ETH_PP: "0x9AA2F6293299DaF2b2711cE514aDbE29400DF885",
    "9INCH_PP": "0x596c43f172bE1Ed5d39Fb1888AE1Cf08Fe18c4C0",
    ETH_PLD: "0x5ad7315c89A023f02e33FA65b783EE8C2AfBBcc0",
    "9INCH_PLD": "0x52d20Ab642d6f20Df955a47566CF40259cced897",
    "9INCH_HEX": "0xC44CF33160EE4270C48310970337685189117901",
    ETH_HEX: "0xE2623a1c7CDBAdC86777b015217F66cDfe8DabDF",
    PpVault: "0xB4eA8057d6c256ced03c8BD68480B735e2a1d6F8",
    PpFlexibleVault: "0xBD2e147e82c58AC299713eBCE91b0Ad37E1811B3",
    PldVault: "0xb60087897149130BD338068845fC3F38715ebe61",
    PldFlexibleVault: "0x27aaf9582980E9D3DE6Fa29ba6B4a7A0eaCE9B72",
    HexVault: "0x553D86b0366e39f37F48b9BFbBA9eA4d30AE45F0",
    HexFlexibleVault: "0x9dDC49f634d1A3CB263F71f237b081d11ca089d6",
    ETH_X: "0xF0eAfd26eE78828d37F0822e1415aB9de94C43f6",
    X: "0x97a2904B064d38075E2CE61943E0933FFd2F3B77",
    SmartChef9INCH: "0x97C6eD1C6FcC213257F7fB637eCC13C2b922f70a",
    SmartChefBBC: "0x93b3a2a990662d3397D8C2f2CD3edd2d9a9Df640"
  }
};
function validateAndParseAddress(address) {
  try {
    const checksummedAddress = getAddress(address);
    warning(address === checksummedAddress, `${address} is not checksummed.`);
    return checksummedAddress;
  } catch (error) {
    invariant5(false, `${address} is not a valid address.`);
  }
}

// src/entities/token.ts
var ERC20Token = class extends Token {
  constructor(chainId, address, decimals, symbol, name, projectLink) {
    super(chainId, validateAndParseAddress(address), decimals, symbol, name, projectLink);
  }
};
var OnRampCurrency = class extends BaseCurrency {
  constructor(chainId, address, decimals, symbol, name, projectLink) {
    super(chainId, decimals, symbol, name);
    this.address = address;
    this.projectLink = projectLink;
    this.isNative = address === "0x" && true;
    this.isToken = address !== "0x" && true;
  }
  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  equals(other) {
    return other.isToken && this.chainId === other.chainId && this.address === other.address;
  }
  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  sortsBefore(other) {
    if (!other.isToken)
      return false;
    invariant5(this.chainId === other.chainId, "CHAIN_IDS");
    invariant5(this.address !== other.address, "ADDRESSES");
    return this.address.toLowerCase() < other.address.toLowerCase();
  }
  /**
   * Return this token, which does not need to be wrapped
   */
  get wrapped() {
    return this;
  }
  get serialize() {
    return {
      address: this.address,
      chainId: this.chainId,
      decimals: this.decimals,
      symbol: this.symbol,
      name: this.name,
      projectLink: this.projectLink
    };
  }
};

// src/constants.ts
var ChainDefaultId = ChainId.PULSE;
var ChainConfig = v2config_default;
var ChainIdList = [
  ChainId.ETHEREUM,
  ChainId.BSC,
  ChainId.PULSE,
  ChainId.GOERLI
];
var mapChainConfig = (field, callback) => {
  return Object.entries(v2config_default).reduce((prev, [key, config]) => {
    const chainId = Number(key);
    const entry = callback ? callback(chainId, config[field]) : config[field];
    if (entry)
      return { ...prev, [chainId]: entry };
    return prev;
  }, {});
};
var ZERO_PERCENT = new Percent("0");
var ONE_HUNDRED_PERCENT = new Percent("1");
var FACTORY_ADDRESS = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
var FACTORY_ADDRESS_ETH = "0x1097053Fd2ea711dad45caCcc45EfF7548fCB362";
var FACTORY_ADDRESS_MAP = {
  [ChainId.ETHEREUM]: FACTORY_ADDRESS_ETH,
  [ChainId.GOERLI]: FACTORY_ADDRESS_ETH,
  [ChainId.BSC]: FACTORY_ADDRESS,
  [ChainId.BSC_TESTNET]: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
  [ChainId.ARBITRUM_ONE]: "0x02a84c1b3BBD7401a5f7fa98a384EBC70bB5749E",
  [ChainId.ARBITRUM_GOERLI]: "0x333EAE459075b1d7dE8eb57997b5d4eee5F1070a",
  [ChainId.POLYGON_ZKEVM]: "0x02a84c1b3BBD7401a5f7fa98a384EBC70bB5749E",
  [ChainId.POLYGON_ZKEVM_TESTNET]: "0xBA40c83026213F9cbc79998752721a0312bdB74a",
  [ChainId.ZKSYNC]: "0xd03D8D566183F0086d8D09A84E1e30b58Dd5619d",
  [ChainId.ZKSYNC_TESTNET]: "0x48a33610Cd0E130af2024D55F67aE72a8C51aC27",
  [ChainId.LINEA]: "0x02a84c1b3BBD7401a5f7fa98a384EBC70bB5749E",
  [ChainId.LINEA_TESTNET]: "0xB6FAfd4ADbCd21cF665909767e0eD0D05709abfB",
  [ChainId.OPBNB]: "0x02a84c1b3BBD7401a5f7fa98a384EBC70bB5749E",
  [ChainId.OPBNB_TESTNET]: "0x776e4bD2f72de2176A59465e316335aaf8ed4E8F",
  [ChainId.BASE]: "0x02a84c1b3BBD7401a5f7fa98a384EBC70bB5749E",
  [ChainId.BASE_TESTNET]: "0x715303D2eF7dA7FFAbF637651D71FD11d41fAf7F",
  [ChainId.BLOCKSPOT_TESTNET]: "0x98EabdC49aF992b0422A2F72E23ea652AdF1b05F",
  [ChainId.PULSE]: NineInchConfig[ChainId.PULSE].NineInchFactory
};
var INIT_CODE_HASH = "0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5";
var INIT_CODE_HASH_ETH = "0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d";
var BLOCKSPOT_CODE_HASH_ETH = "0x6daf4e163be1365560ba4d2245da10cb9918e39f0704a70fd4353f112db41ef8";
var PULSE_CODE_HASH_ETH = NineInchConfig[ChainId.PULSE].INIT_CODE_HASH_V2;
var INIT_CODE_HASH_MAP = {
  [ChainId.ETHEREUM]: INIT_CODE_HASH_ETH,
  [ChainId.GOERLI]: INIT_CODE_HASH_ETH,
  [ChainId.BSC]: INIT_CODE_HASH,
  [ChainId.BSC_TESTNET]: "0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66",
  [ChainId.ARBITRUM_ONE]: INIT_CODE_HASH_ETH,
  [ChainId.ARBITRUM_GOERLI]: INIT_CODE_HASH_ETH,
  [ChainId.POLYGON_ZKEVM]: INIT_CODE_HASH_ETH,
  [ChainId.POLYGON_ZKEVM_TESTNET]: INIT_CODE_HASH_ETH,
  [ChainId.ZKSYNC]: "0x0100045707a42494392b3558029b9869f865ff9df8f375dc1bf20b0555093f43",
  [ChainId.ZKSYNC_TESTNET]: "0x0100045707a42494392b3558029b9869f865ff9df8f375dc1bf20b0555093f43",
  [ChainId.LINEA]: INIT_CODE_HASH_ETH,
  [ChainId.LINEA_TESTNET]: INIT_CODE_HASH_ETH,
  [ChainId.OPBNB]: INIT_CODE_HASH_ETH,
  [ChainId.OPBNB_TESTNET]: INIT_CODE_HASH_ETH,
  [ChainId.BASE]: INIT_CODE_HASH_ETH,
  [ChainId.BASE_TESTNET]: "0xa5934690703a592a07e841ca29d5e5c79b5e22ed4749057bb216dc31100be1c0",
  [ChainId.BLOCKSPOT_TESTNET]: BLOCKSPOT_CODE_HASH_ETH,
  // INIT_CODE_HASH_ETH,
  [ChainId.PULSE]: PULSE_CODE_HASH_ETH
};
var WETH9 = {
  [ChainId.ETHEREUM]: new ERC20Token(
    ChainId.ETHEREUM,
    "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.GOERLI]: new ERC20Token(
    ChainId.GOERLI,
    "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.BSC]: new ERC20Token(
    ChainId.BSC,
    "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    18,
    "ETH",
    "Binance-Peg Ethereum Token",
    "https://ethereum.org"
  ),
  [ChainId.BSC_TESTNET]: new ERC20Token(
    ChainId.BSC,
    "0xE7bCB9e341D546b66a46298f4893f5650a56e99E",
    18,
    "ETH",
    "ETH",
    "https://ethereum.org"
  ),
  [ChainId.ARBITRUM_ONE]: new ERC20Token(
    ChainId.ARBITRUM_ONE,
    "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.ARBITRUM_GOERLI]: new ERC20Token(
    ChainId.ARBITRUM_GOERLI,
    "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.ZKSYNC]: new ERC20Token(
    ChainId.ZKSYNC,
    "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.ZKSYNC_TESTNET]: new ERC20Token(
    ChainId.ZKSYNC_TESTNET,
    "0x02968DB286f24cB18bB5b24903eC8eBFAcf591C0",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.POLYGON_ZKEVM]: new ERC20Token(
    ChainId.POLYGON_ZKEVM,
    "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.POLYGON_ZKEVM_TESTNET]: new ERC20Token(
    ChainId.POLYGON_ZKEVM_TESTNET,
    "0x30ec47F7DFae72eA79646e6cf64a8A7db538915b",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.LINEA]: new ERC20Token(
    ChainId.LINEA,
    "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.LINEA_TESTNET]: new ERC20Token(
    ChainId.LINEA_TESTNET,
    "0x2C1b868d6596a18e32E61B901E4060C872647b6C",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.OPBNB_TESTNET]: new ERC20Token(
    ChainId.OPBNB_TESTNET,
    "0x584f7b986d9942B0859a1E6921efA5342A673d04",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.OPBNB]: new ERC20Token(
    ChainId.OPBNB,
    "0xE7798f023fC62146e8Aa1b36Da45fb70855a77Ea",
    18,
    "ETH",
    "Binance-Peg Ethereum Token",
    "https://ethereum.org"
  ),
  [ChainId.BASE]: new ERC20Token(
    ChainId.BASE,
    "0x4200000000000000000000000000000000000006",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.BASE_TESTNET]: new ERC20Token(
    ChainId.BASE_TESTNET,
    "0x4200000000000000000000000000000000000006",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.BLOCKSPOT_TESTNET]: new ERC20Token(
    ChainId.BLOCKSPOT_TESTNET,
    "0x14229c0930C9792B39ECB08028A2784A016935aC",
    18,
    "WETH",
    "Wrapped Ether",
    "https://weth.io"
  ),
  [ChainId.PULSE]: new ERC20Token(
    ChainId.PULSE,
    NineInchConfig[ChainId.PULSE].WPLS,
    18,
    "WPLS",
    "Wrapped PLS",
    "https://wpls.io"
  )
};
var WBNB = {
  [ChainId.ETHEREUM]: new ERC20Token(
    ChainId.ETHEREUM,
    "0x418D75f65a02b3D53B2418FB8E1fe493759c7605",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  ),
  [ChainId.BSC]: new ERC20Token(
    ChainId.BSC,
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  ),
  [ChainId.BSC_TESTNET]: new ERC20Token(
    ChainId.BSC_TESTNET,
    "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  ),
  [ChainId.OPBNB_TESTNET]: new ERC20Token(
    ChainId.OPBNB_TESTNET,
    "0x4200000000000000000000000000000000000006",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  ),
  [ChainId.OPBNB]: new ERC20Token(
    ChainId.OPBNB,
    "0x4200000000000000000000000000000000000006",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.org"
  )
};
var WPLS = {
  [ChainId.PULSE]: new ERC20Token(
    ChainId.PULSE,
    NineInchConfig[ChainId.PULSE].WPLS,
    18,
    "WPLS",
    "Wrapped Pulse",
    "https://www.wpls.io"
  )
};
var WNATIVE = {
  [ChainId.ETHEREUM]: WETH9[ChainId.ETHEREUM],
  [ChainId.GOERLI]: WETH9[ChainId.GOERLI],
  [ChainId.BSC]: WBNB[ChainId.BSC],
  [ChainId.BSC_TESTNET]: WBNB[ChainId.BSC_TESTNET],
  [ChainId.ARBITRUM_ONE]: WETH9[ChainId.ARBITRUM_ONE],
  [ChainId.ARBITRUM_GOERLI]: WETH9[ChainId.ARBITRUM_GOERLI],
  [ChainId.POLYGON_ZKEVM]: WETH9[ChainId.POLYGON_ZKEVM],
  [ChainId.POLYGON_ZKEVM_TESTNET]: WETH9[ChainId.POLYGON_ZKEVM_TESTNET],
  [ChainId.ZKSYNC]: WETH9[ChainId.ZKSYNC],
  [ChainId.ZKSYNC_TESTNET]: WETH9[ChainId.ZKSYNC_TESTNET],
  [ChainId.LINEA]: WETH9[ChainId.LINEA],
  [ChainId.LINEA_TESTNET]: WETH9[ChainId.LINEA_TESTNET],
  [ChainId.OPBNB_TESTNET]: WBNB[ChainId.OPBNB_TESTNET],
  [ChainId.OPBNB]: WBNB[ChainId.OPBNB],
  [ChainId.BASE]: WETH9[ChainId.BASE],
  [ChainId.BASE_TESTNET]: WETH9[ChainId.BASE_TESTNET],
  [ChainId.BLOCKSPOT_TESTNET]: WETH9[ChainId.BLOCKSPOT_TESTNET],
  [ChainId.PULSE]: WPLS[ChainId.PULSE]
};
var ETHER = { name: "Ether", symbol: "ETH", decimals: 18 };
var BNB = {
  name: "Binance Chain Native Token",
  symbol: "BNB",
  decimals: 18
};
var NATIVE = {
  [ChainId.ETHEREUM]: ETHER,
  [ChainId.GOERLI]: { name: "Goerli Ether", symbol: "GOR", decimals: 18 },
  [ChainId.BSC]: BNB,
  [ChainId.BSC_TESTNET]: {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
  },
  [ChainId.ARBITRUM_ONE]: ETHER,
  [ChainId.ARBITRUM_GOERLI]: {
    name: "Arbitrum Goerli Ether",
    symbol: "AGOR",
    decimals: 18
  },
  [ChainId.POLYGON_ZKEVM]: ETHER,
  [ChainId.POLYGON_ZKEVM_TESTNET]: ETHER,
  [ChainId.ZKSYNC]: ETHER,
  [ChainId.ZKSYNC_TESTNET]: ETHER,
  [ChainId.LINEA]: ETHER,
  [ChainId.LINEA_TESTNET]: ETHER,
  [ChainId.OPBNB]: BNB,
  [ChainId.OPBNB_TESTNET]: {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
  },
  [ChainId.BASE]: ETHER,
  [ChainId.BASE_TESTNET]: ETHER,
  [ChainId.BLOCKSPOT_TESTNET]: ETHER,
  [ChainId.PULSE]: {
    name: "Pulse Native Token",
    symbol: "PLS",
    decimals: 18
  }
};

// src/trade.ts
function isTradeBetter(tradeA, tradeB, minimumDelta = ZERO_PERCENT) {
  if (tradeA && !tradeB)
    return false;
  if (tradeB && !tradeA)
    return true;
  if (!tradeA || !tradeB)
    return void 0;
  if (tradeA.tradeType !== tradeB.tradeType || !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) || !tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency)) {
    throw new Error("Trades are not comparable");
  }
  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  }
  return tradeA.executionPrice.asFraction.multiply(minimumDelta.add(ONE_HUNDRED_PERCENT)).lessThan(tradeB.executionPrice);
}
var PAIR_ADDRESS_CACHE = {};
var composeKey = (token0, token1) => `${token0.chainId}-${token0.address}-${token1.address}`;
function getCreate2Address(from_, salt_, initCodeHash) {
  const from = toBytes(getAddress(from_));
  const salt = pad(isBytes(salt_) ? salt_ : toBytes(salt_), {
    size: 32
  });
  return getAddress(slice(keccak256(concat([toBytes("0xff"), from, salt, toBytes(initCodeHash)])), 12));
}
var EMPTY_INPU_HASH = "0xc5d2460186f7233c927e7db2dcc703c0e500b653ca82273b7bfad8045d85a470";
var ZKSYNC_PREFIX = "0x2020dba91b30cc0006188af794c2fb30dd8520db7e2c088b7fc7c103c00ca494";
function getCreate2AddressZkSync(from, salt, initCodeHash) {
  return getAddress(
    `0x${keccak256(concat([ZKSYNC_PREFIX, pad(from, { size: 32 }), salt, initCodeHash, EMPTY_INPU_HASH])).slice(26)}`
  );
}
var computePairAddress = ({
  factoryAddress,
  tokenA,
  tokenB
}) => {
  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
  const key = composeKey(token0, token1);
  if (PAIR_ADDRESS_CACHE?.[key] === void 0) {
    const getCreate2Address_ = token0.chainId === ChainId.ZKSYNC_TESTNET || token1.chainId === ChainId.ZKSYNC ? getCreate2AddressZkSync : getCreate2Address;
    PAIR_ADDRESS_CACHE = {
      ...PAIR_ADDRESS_CACHE,
      [key]: getCreate2Address_(
        factoryAddress,
        keccak256(encodePacked(["address", "address"], [token0.address, token1.address])),
        INIT_CODE_HASH_MAP[token0.chainId]
      )
    };
  }
  return PAIR_ADDRESS_CACHE[key];
};
var Pair = class {
  static getAddress(tokenA, tokenB) {
    return computePairAddress({
      factoryAddress: FACTORY_ADDRESS_MAP[tokenA.chainId],
      tokenA,
      tokenB
    });
  }
  constructor(currencyAmountA, tokenAmountB) {
    const tokenAmounts = currencyAmountA.currency.sortsBefore(tokenAmountB.currency) ? [currencyAmountA, tokenAmountB] : [tokenAmountB, currencyAmountA];
    this.liquidityToken = new ERC20Token(
      tokenAmounts[0].currency.chainId,
      Pair.getAddress(tokenAmounts[0].currency, tokenAmounts[1].currency),
      18,
      "Nineinch-LP",
      "Nineinch LPs"
    );
    this.tokenAmounts = tokenAmounts;
  }
  /**
   * Returns true if the token is either token0 or token1
   * @param token to check
   */
  involvesToken(token) {
    if (token === void 0) {
      return false;
    }
    return token.equals(this.token0) || token.equals(this.token1);
  }
  /**
   * Returns the current mid price of the pair in terms of token0, i.e. the ratio of reserve1 to reserve0
   */
  get token0Price() {
    const result = this.tokenAmounts[1].divide(this.tokenAmounts[0]);
    return new Price(this.token0, this.token1, result.denominator, result.numerator);
  }
  /**
   * Returns the current mid price of the pair in terms of token1, i.e. the ratio of reserve0 to reserve1
   */
  get token1Price() {
    const result = this.tokenAmounts[0].divide(this.tokenAmounts[1]);
    return new Price(this.token1, this.token0, result.denominator, result.numerator);
  }
  /**
   * Return the price of the given token in terms of the other token in the pair.
   * @param token token to return price of
   */
  priceOf(token) {
    if (!token) {
      return new Price(token, token, ZERO, ONE);
    }
    try {
      invariant5(this.involvesToken(token), "TOKEN");
      return token.equals(this.token0) ? this.token0Price : this.token1Price;
    } catch (e) {
      return new Price(token, token, ZERO, ONE);
    }
  }
  /**
   * Returns the chain ID of the tokens in the pair.
   */
  get chainId() {
    return this.token0.chainId;
  }
  get token0() {
    return this.tokenAmounts[0].currency;
  }
  get token1() {
    return this.tokenAmounts[1].currency;
  }
  get reserve0() {
    return this.tokenAmounts[0];
  }
  get reserve1() {
    return this.tokenAmounts[1];
  }
  reserveOf(token) {
    invariant5(this.involvesToken(token), "TOKEN");
    return token.equals(this.token0) ? this.reserve0 : this.reserve1;
  }
  getOutputAmount(inputAmount) {
    invariant5(this.involvesToken(inputAmount.currency), "TOKEN");
    if (this.reserve0.quotient === ZERO || this.reserve1.quotient === ZERO) {
      throw new InsufficientReservesError();
    }
    const inputReserve = this.reserveOf(inputAmount.currency);
    const outputReserve = this.reserveOf(inputAmount.currency.equals(this.token0) ? this.token1 : this.token0);
    const inputAmountWithFee = inputAmount.quotient * _9970;
    const numerator = inputAmountWithFee * outputReserve.quotient;
    const denominator = inputReserve.quotient * _10000 + inputAmountWithFee;
    const outputAmount = CurrencyAmount.fromRawAmount(
      inputAmount.currency.equals(this.token0) ? this.token1 : this.token0,
      numerator / denominator
    );
    if (outputAmount.quotient === ZERO) {
      throw new InsufficientInputAmountError();
    }
    return [outputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
  }
  getInputAmount(outputAmount) {
    invariant5(this.involvesToken(outputAmount.currency), "TOKEN");
    if (this.reserve0.quotient === ZERO || this.reserve1.quotient === ZERO || outputAmount.quotient >= this.reserveOf(outputAmount.currency).quotient) {
      throw new InsufficientReservesError();
    }
    const outputReserve = this.reserveOf(outputAmount.currency);
    const inputReserve = this.reserveOf(outputAmount.currency.equals(this.token0) ? this.token1 : this.token0);
    const numerator = inputReserve.quotient * outputAmount.quotient * _10000;
    const denominator = (outputReserve.quotient - outputAmount.quotient) * _9975;
    const inputAmount = CurrencyAmount.fromRawAmount(
      outputAmount.currency.equals(this.token0) ? this.token1 : this.token0,
      numerator / denominator + ONE
    );
    return [inputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))];
  }
  getLiquidityMinted(totalSupply, tokenAmountA, tokenAmountB) {
    invariant5(totalSupply.currency.equals(this.liquidityToken), "LIQUIDITY");
    const tokenAmounts = tokenAmountA.currency.sortsBefore(tokenAmountB.currency) ? [tokenAmountA, tokenAmountB] : [tokenAmountB, tokenAmountA];
    invariant5(tokenAmounts[0].currency.equals(this.token0) && tokenAmounts[1].currency.equals(this.token1), "TOKEN");
    let liquidity;
    if (totalSupply.quotient === ZERO) {
      liquidity = sqrt(tokenAmounts[0].quotient * tokenAmounts[1].quotient) - MINIMUM_LIQUIDITY;
    } else {
      const amount0 = tokenAmounts[0].quotient * totalSupply.quotient / this.reserve0.quotient;
      const amount1 = tokenAmounts[1].quotient * totalSupply.quotient / this.reserve1.quotient;
      liquidity = amount0 <= amount1 ? amount0 : amount1;
    }
    if (!(liquidity > ZERO)) {
      throw new InsufficientInputAmountError();
    }
    return CurrencyAmount.fromRawAmount(this.liquidityToken, liquidity);
  }
  getLiquidityValue(token, totalSupply, liquidity, feeOn = false, kLast) {
    invariant5(this.involvesToken(token), "TOKEN");
    invariant5(totalSupply.currency.equals(this.liquidityToken), "TOTAL_SUPPLY");
    invariant5(liquidity.currency.equals(this.liquidityToken), "LIQUIDITY");
    invariant5(liquidity.quotient <= totalSupply.quotient, "LIQUIDITY");
    let totalSupplyAdjusted;
    if (!feeOn) {
      totalSupplyAdjusted = totalSupply;
    } else {
      invariant5(!!kLast, "K_LAST");
      const kLastParsed = BigInt(kLast);
      if (!(kLastParsed === ZERO)) {
        const rootK = sqrt(this.reserve0.quotient * this.reserve1.quotient);
        const rootKLast = sqrt(kLastParsed);
        if (rootK > rootKLast) {
          const numerator = totalSupply.quotient * (rootK - rootKLast);
          const denominator = rootK * FIVE + rootKLast;
          const feeLiquidity = numerator / denominator;
          totalSupplyAdjusted = totalSupply.add(CurrencyAmount.fromRawAmount(this.liquidityToken, feeLiquidity));
        } else {
          totalSupplyAdjusted = totalSupply;
        }
      } else {
        totalSupplyAdjusted = totalSupply;
      }
    }
    return CurrencyAmount.fromRawAmount(
      token,
      liquidity.quotient * this.reserveOf(token).quotient / totalSupplyAdjusted.quotient
    );
  }
};
var Route = class {
  constructor(pairs, input, output) {
    this._midPrice = null;
    invariant5(pairs.length > 0, "PAIRS");
    const { chainId } = pairs[0];
    invariant5(
      pairs.every((pair) => pair.chainId === chainId),
      "CHAIN_IDS"
    );
    const wrappedInput = input.wrapped;
    invariant5(pairs[0].involvesToken(wrappedInput), "INPUT");
    invariant5(typeof output === "undefined" || pairs[pairs.length - 1].involvesToken(output.wrapped), "OUTPUT");
    const path = [wrappedInput];
    for (const [i, pair] of pairs.entries()) {
      const currentInput = path[i];
      invariant5(currentInput.equals(pair.token0) || currentInput.equals(pair.token1), "PATH");
      const output2 = currentInput.equals(pair.token0) ? pair.token1 : pair.token0;
      path.push(output2);
    }
    this.pairs = pairs;
    this.path = path;
    this.input = input;
    this.output = output;
  }
  get midPrice() {
    if (this._midPrice !== null)
      return this._midPrice;
    const prices = [];
    for (const [i, pair] of this.pairs.entries()) {
      prices.push(
        this.path[i].equals(pair.token0) ? new Price(pair.reserve0.currency, pair.reserve1.currency, pair.reserve0.quotient, pair.reserve1.quotient) : new Price(pair.reserve1.currency, pair.reserve0.currency, pair.reserve1.quotient, pair.reserve0.quotient)
      );
    }
    const reduced = prices.slice(1).reduce((accumulator, currentValue) => accumulator.multiply(currentValue), prices[0]);
    return this._midPrice = new Price(this.input, this.output, reduced.denominator, reduced.numerator);
  }
  get chainId() {
    return this.pairs[0].chainId;
  }
};
function inputOutputComparator(a, b) {
  invariant5(a.inputAmount.currency.equals(b.inputAmount.currency), "INPUT_CURRENCY");
  invariant5(a.outputAmount.currency.equals(b.outputAmount.currency), "OUTPUT_CURRENCY");
  if (a.outputAmount.equalTo(b.outputAmount)) {
    if (a.inputAmount.equalTo(b.inputAmount)) {
      return 0;
    }
    if (a.inputAmount.lessThan(b.inputAmount)) {
      return -1;
    }
    return 1;
  }
  if (a.outputAmount.lessThan(b.outputAmount)) {
    return 1;
  }
  return -1;
}
function tradeComparator(a, b) {
  const ioComp = inputOutputComparator(a, b);
  if (ioComp !== 0) {
    return ioComp;
  }
  if (a.priceImpact.lessThan(b.priceImpact)) {
    return -1;
  }
  if (a.priceImpact.greaterThan(b.priceImpact)) {
    return 1;
  }
  return a.route.path.length - b.route.path.length;
}
var Trade = class {
  /**
   * Constructs an exact in trade with the given amount in and route
   * @param route route of the exact in trade
   * @param amountIn the amount being passed in
   */
  static exactIn(route, amountIn) {
    return new Trade(route, amountIn, TradeType.EXACT_INPUT);
  }
  /**
   * Constructs an exact out trade with the given amount out and route
   * @param route route of the exact out trade
   * @param amountOut the amount returned by the trade
   */
  static exactOut(route, amountOut) {
    return new Trade(route, amountOut, TradeType.EXACT_OUTPUT);
  }
  constructor(route, amount, tradeType) {
    this.route = route;
    this.tradeType = tradeType;
    const tokenAmounts = new Array(route.path.length);
    if (tradeType === TradeType.EXACT_INPUT) {
      invariant5(amount.currency.equals(route.input), "INPUT");
      tokenAmounts[0] = amount.wrapped;
      for (let i = 0; i < route.path.length - 1; i++) {
        const pair = route.pairs[i];
        const [outputAmount] = pair.getOutputAmount(tokenAmounts[i]);
        tokenAmounts[i + 1] = outputAmount;
      }
      this.inputAmount = CurrencyAmount.fromFractionalAmount(route.input, amount.numerator, amount.denominator);
      this.outputAmount = CurrencyAmount.fromFractionalAmount(
        route.output,
        tokenAmounts[tokenAmounts.length - 1].numerator,
        tokenAmounts[tokenAmounts.length - 1].denominator
      );
    } else {
      invariant5(amount.currency.equals(route.output), "OUTPUT");
      tokenAmounts[tokenAmounts.length - 1] = amount.wrapped;
      for (let i = route.path.length - 1; i > 0; i--) {
        const pair = route.pairs[i - 1];
        const [inputAmount] = pair.getInputAmount(tokenAmounts[i]);
        tokenAmounts[i - 1] = inputAmount;
      }
      this.inputAmount = CurrencyAmount.fromFractionalAmount(
        route.input,
        tokenAmounts[0].numerator,
        tokenAmounts[0].denominator
      );
      this.outputAmount = CurrencyAmount.fromFractionalAmount(route.output, amount.numerator, amount.denominator);
    }
    this.executionPrice = new Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.inputAmount.quotient,
      this.outputAmount.quotient
    );
    this.priceImpact = computePriceImpact(route.midPrice, this.inputAmount, this.outputAmount);
  }
  /**
   * Get the minimum amount that must be received from this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  minimumAmountOut(slippageTolerance) {
    invariant5(!slippageTolerance.lessThan(ZERO), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType.EXACT_OUTPUT) {
      return this.outputAmount;
    }
    const slippageAdjustedAmountOut = new Fraction(ONE).add(slippageTolerance).invert().multiply(this.outputAmount.quotient).quotient;
    return CurrencyAmount.fromRawAmount(this.outputAmount.currency, slippageAdjustedAmountOut);
  }
  /**
   * Get the maximum amount in that can be spent via this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  maximumAmountIn(slippageTolerance) {
    invariant5(!slippageTolerance.lessThan(ZERO), "SLIPPAGE_TOLERANCE");
    if (this.tradeType === TradeType.EXACT_INPUT) {
      return this.inputAmount;
    }
    const slippageAdjustedAmountIn = new Fraction(ONE).add(slippageTolerance).multiply(this.inputAmount.quotient).quotient;
    return CurrencyAmount.fromRawAmount(this.inputAmount.currency, slippageAdjustedAmountIn);
  }
  /**
   * Given a list of pairs, and a fixed amount in, returns the top `maxNumResults` trades that go from an input token
   * amount to an output token, making at most `maxHops` hops.
   * Note this does not consider aggregation, as routes are linear. It's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pairs the pairs to consider in finding the best trade
   * @param nextAmountIn exact amount of input currency to spend
   * @param currencyOut the desired currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pair
   * @param currentPairs used in recursion; the current list of pairs
   * @param currencyAmountIn used in recursion; the original value of the currencyAmountIn parameter
   * @param bestTrades used in recursion; the current list of best trades
   */
  static bestTradeExactIn(pairs, currencyAmountIn, currencyOut, { maxNumResults = 3, maxHops = 3 } = {}, currentPairs = [], nextAmountIn = currencyAmountIn, bestTrades = []) {
    invariant5(pairs.length > 0, "PAIRS");
    invariant5(maxHops > 0, "MAX_HOPS");
    invariant5(currencyAmountIn === nextAmountIn || currentPairs.length > 0, "INVALID_RECURSION");
    const amountIn = nextAmountIn.wrapped;
    const tokenOut = currencyOut.wrapped;
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      if (!pair.token0.equals(amountIn.currency) && !pair.token1.equals(amountIn.currency))
        continue;
      if (pair.reserve0.equalTo(ZERO) || pair.reserve1.equalTo(ZERO))
        continue;
      let amountOut;
      try {
        ;
        [amountOut] = pair.getOutputAmount(amountIn);
      } catch (error) {
        if (error.isInsufficientInputAmountError) {
          continue;
        }
        throw error;
      }
      if (amountOut.currency.equals(tokenOut)) {
        sortedInsert(
          bestTrades,
          new Trade(
            new Route([...currentPairs, pair], currencyAmountIn.currency, currencyOut),
            currencyAmountIn,
            TradeType.EXACT_INPUT
          ),
          maxNumResults,
          tradeComparator
        );
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs.slice(0, i).concat(pairs.slice(i + 1, pairs.length));
        Trade.bestTradeExactIn(
          pairsExcludingThisPair,
          currencyAmountIn,
          currencyOut,
          {
            maxNumResults,
            maxHops: maxHops - 1
          },
          [...currentPairs, pair],
          amountOut,
          bestTrades
        );
      }
    }
    return bestTrades;
  }
  /**
   * Return the execution price after accounting for slippage tolerance
   * @param slippageTolerance the allowed tolerated slippage
   */
  worstExecutionPrice(slippageTolerance) {
    return new Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.maximumAmountIn(slippageTolerance).quotient,
      this.minimumAmountOut(slippageTolerance).quotient
    );
  }
  /**
   * similar to the above method but instead targets a fixed output amount
   * given a list of pairs, and a fixed amount out, returns the top `maxNumResults` trades that go from an input token
   * to an output token amount, making at most `maxHops` hops
   * note this does not consider aggregation, as routes are linear. it's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param pairs the pairs to consider in finding the best trade
   * @param currencyIn the currency to spend
   * @param nextAmountOut the exact amount of currency out
   * @param maxNumResults maximum number of results to return
   * @param maxHops maximum number of hops a returned trade can make, e.g. 1 hop goes through a single pair
   * @param currentPairs used in recursion; the current list of pairs
   * @param currencyAmountOut used in recursion; the original value of the currencyAmountOut parameter
   * @param bestTrades used in recursion; the current list of best trades
   */
  static bestTradeExactOut(pairs, currencyIn, currencyAmountOut, { maxNumResults = 3, maxHops = 3 } = {}, currentPairs = [], nextAmountOut = currencyAmountOut, bestTrades = []) {
    invariant5(pairs.length > 0, "PAIRS");
    invariant5(maxHops > 0, "MAX_HOPS");
    invariant5(currencyAmountOut === nextAmountOut || currentPairs.length > 0, "INVALID_RECURSION");
    const amountOut = nextAmountOut.wrapped;
    const tokenIn = currencyIn.wrapped;
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i];
      if (!pair.token0.equals(amountOut.currency) && !pair.token1.equals(amountOut.currency))
        continue;
      if (pair.reserve0.equalTo(ZERO) || pair.reserve1.equalTo(ZERO))
        continue;
      let amountIn;
      try {
        ;
        [amountIn] = pair.getInputAmount(amountOut);
      } catch (error) {
        if (error.isInsufficientReservesError) {
          continue;
        }
        throw error;
      }
      if (amountIn.currency.equals(tokenIn)) {
        sortedInsert(
          bestTrades,
          new Trade(
            new Route([pair, ...currentPairs], currencyIn, currencyAmountOut.currency),
            currencyAmountOut,
            TradeType.EXACT_OUTPUT
          ),
          maxNumResults,
          tradeComparator
        );
      } else if (maxHops > 1 && pairs.length > 1) {
        const pairsExcludingThisPair = pairs.slice(0, i).concat(pairs.slice(i + 1, pairs.length));
        Trade.bestTradeExactOut(
          pairsExcludingThisPair,
          currencyIn,
          currencyAmountOut,
          {
            maxNumResults,
            maxHops: maxHops - 1
          },
          [pair, ...currentPairs],
          amountIn,
          bestTrades
        );
      }
    }
    return bestTrades;
  }
};
var _Native = class extends NativeCurrency {
  constructor({
    chainId,
    decimals,
    name,
    symbol
  }) {
    super(chainId, decimals, symbol, name);
  }
  get wrapped() {
    const wnative = WNATIVE[this.chainId];
    invariant5(!!wnative, "WRAPPED");
    return wnative;
  }
  static onChain(chainId) {
    if (chainId in this.cache) {
      return this.cache[chainId];
    }
    invariant5(!!NATIVE[chainId], "NATIVE_CURRENCY");
    const { decimals, name, symbol } = NATIVE[chainId];
    return this.cache[chainId] = new _Native({ chainId, decimals, symbol, name });
  }
  equals(other) {
    return other.isNative && other.chainId === this.chainId;
  }
};
var Native = _Native;
Native.cache = {};
function toHex(currencyAmount) {
  return `0x${currencyAmount.quotient.toString(16)}`;
}
var ZERO_HEX = "0x0";
var Router = class {
  /**
   * Produces the on-chain method name to call and the hex encoded parameters to pass as arguments for a given trade.
   * @param trade to produce call parameters for
   * @param options options for the call parameters
   */
  static swapCallParameters(trade, options) {
    const etherIn = trade.inputAmount.currency.isNative;
    const etherOut = trade.outputAmount.currency.isNative;
    invariant5(!(etherIn && etherOut), "ETHER_IN_OUT");
    invariant5(!("ttl" in options) || options.ttl > 0, "TTL");
    const to = validateAndParseAddress(options.recipient);
    const amountIn = toHex(trade.maximumAmountIn(options.allowedSlippage));
    const amountOut = toHex(trade.minimumAmountOut(options.allowedSlippage));
    const path = trade.route.path.map((token) => token.address);
    const deadline = "ttl" in options ? `0x${(Math.floor(( new Date()).getTime() / 1e3) + options.ttl).toString(16)}` : `0x${options.deadline.toString(16)}`;
    const useFeeOnTransfer = Boolean(options.feeOnTransfer);
    let methodName;
    let args;
    let value;
    switch (trade.tradeType) {
      case TradeType.EXACT_INPUT:
        if (etherIn) {
          methodName = useFeeOnTransfer ? "swapExactETHForTokensSupportingFeeOnTransferTokens" : "swapExactETHForTokens";
          args = [amountOut, path, to, deadline];
          value = amountIn;
        } else if (etherOut) {
          methodName = useFeeOnTransfer ? "swapExactTokensForETHSupportingFeeOnTransferTokens" : "swapExactTokensForETH";
          args = [amountIn, amountOut, path, to, deadline];
          value = ZERO_HEX;
        } else {
          methodName = useFeeOnTransfer ? "swapExactTokensForTokensSupportingFeeOnTransferTokens" : "swapExactTokensForTokens";
          args = [amountIn, amountOut, path, to, deadline];
          value = ZERO_HEX;
        }
        break;
      case TradeType.EXACT_OUTPUT:
        invariant5(!useFeeOnTransfer, "EXACT_OUT_FOT");
        if (etherIn) {
          methodName = "swapETHForExactTokens";
          args = [amountOut, path, to, deadline];
          value = amountIn;
        } else if (etherOut) {
          methodName = "swapTokensForExactETH";
          args = [amountOut, amountIn, path, to, deadline];
          value = ZERO_HEX;
        } else {
          methodName = "swapTokensForExactTokens";
          args = [amountOut, amountIn, path, to, deadline];
          value = ZERO_HEX;
        }
        break;
    }
    return {
      methodName,
      args,
      value
    };
  }
};
var _Ether = class extends NativeCurrency {
  constructor(chainId) {
    super(chainId, 18, "ETH", "Ether");
  }
  get wrapped() {
    const weth9 = WETH9[this.chainId];
    invariant5(!!weth9, "WRAPPED");
    return weth9;
  }
  static onChain(chainId) {
    if (!this._etherCache[chainId]) {
      this._etherCache[chainId] = new _Ether(chainId);
    }
    return this._etherCache[chainId];
  }
  equals(other) {
    return other.isNative && other.chainId === this.chainId;
  }
};
var Ether = _Ether;
Ether._etherCache = {};

// src/abis/ERC20.ts
var erc20ABI = [
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [{ name: "", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function"
  }
];

// src/abis/IPancakePair.ts
var pancakePairV2ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "Approval",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "Burn",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    name: "Mint",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0In",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1In",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0Out",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1Out",
        type: "uint256"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "Swap",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint112",
        name: "reserve0",
        type: "uint112"
      },
      {
        indexed: false,
        internalType: "uint112",
        name: "reserve1",
        type: "uint112"
      }
    ],
    name: "Sync",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "Transfer",
    type: "event"
  },
  {
    constant: true,
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "MINIMUM_LIQUIDITY",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "address",
        name: "spender",
        type: "address"
      }
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "burn",
    outputs: [
      {
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "factory",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "getReserves",
    outputs: [
      {
        internalType: "uint112",
        name: "reserve0",
        type: "uint112"
      },
      {
        internalType: "uint112",
        name: "reserve1",
        type: "uint112"
      },
      {
        internalType: "uint32",
        name: "blockTimestampLast",
        type: "uint32"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      },
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    name: "initialize",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "kLast",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "mint",
    outputs: [
      {
        internalType: "uint256",
        name: "liquidity",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "permit",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "price0CumulativeLast",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "price1CumulativeLast",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "skim",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "uint256",
        name: "amount0Out",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1Out",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "swap",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    payable: false,
    stateMutability: "pure",
    type: "function"
  },
  {
    constant: false,
    inputs: [],
    name: "sync",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "token0",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "token1",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  }
];

// src/fetcher.ts
var TOKEN_DECIMALS_CACHE = {
  [ChainId.BSC]: {}
};
var ethClient = createPublicClient({ chain: mainnet, transport: http() });
var bscClient = createPublicClient({ chain: bsc, transport: http() });
var bscTestnetClient = createPublicClient({ chain: bscTestnet, transport: http() });
var goerliClient = createPublicClient({ chain: goerli, transport: http() });
var getDefaultClient = (chainId) => {
  switch (chainId) {
    case ChainId.ETHEREUM:
      return ethClient;
    case ChainId.BSC:
      return bscClient;
    case ChainId.BSC_TESTNET:
      return bscTestnetClient;
    case ChainId.GOERLI:
      return goerliClient;
    default:
      return bscClient;
  }
};
var Fetcher = class {
  /**
   * Cannot be constructed.
   */
  // eslint-disable-next-line no-useless-constructor,@typescript-eslint/no-empty-function
  constructor() {
  }
  /**
   * Fetch information for a given token on the given chain, using the given viem provider.
   * @param chainId chain of the token
   * @param address address of the token on the chain
   * @param provider provider used to fetch the token
   * @param symbol symbol of the token
   * @param name optional name of the token
   */
  static async fetchTokenData(chainId, address, publicClient = getDefaultClient(chainId), symbol, name) {
    const erc20 = getContract({
      abi: erc20ABI,
      address,
      publicClient
    });
    const parsedDecimals = typeof TOKEN_DECIMALS_CACHE?.[chainId]?.[address] === "number" ? TOKEN_DECIMALS_CACHE[chainId][address] : await erc20.read.decimals().then((decimals) => {
      TOKEN_DECIMALS_CACHE = {
        ...TOKEN_DECIMALS_CACHE,
        [chainId]: {
          ...TOKEN_DECIMALS_CACHE?.[chainId],
          [address]: decimals
        }
      };
      return decimals;
    });
    return new Token(chainId, address, parsedDecimals, symbol, name);
  }
  /**
   * Fetches information about a pair and constructs a pair from the given two tokens.
   * @param tokenA first token
   * @param tokenB second token
   * @param provider the provider to use to fetch the data
   */
  static async fetchPairData(tokenA, tokenB, publicClient = getDefaultClient(tokenA.chainId)) {
    invariant5(tokenA.chainId === tokenB.chainId, "CHAIN_ID");
    const address = Pair.getAddress(tokenA, tokenB);
    const pairContract = getContract({
      abi: pancakePairV2ABI,
      address,
      publicClient
    });
    const [reserves0, reserves1] = await pairContract.read.getReserves();
    const balances = tokenA.sortsBefore(tokenB) ? [reserves0, reserves1] : [reserves1, reserves0];
    return new Pair(
      CurrencyAmount.fromRawAmount(tokenA, balances[0]),
      CurrencyAmount.fromRawAmount(tokenB, balances[1])
    );
  }
};

export { ChainConfig, ChainDefaultId, ChainIdList, ERC20Token, Ether, FACTORY_ADDRESS, FACTORY_ADDRESS_MAP, Fetcher, INIT_CODE_HASH, INIT_CODE_HASH_MAP, NATIVE, Native, ONE_HUNDRED_PERCENT, OnRampCurrency, Pair, Route, Router, Trade, WBNB, WETH9, WNATIVE, WPLS, ZERO_PERCENT, computePairAddress, inputOutputComparator, isTradeBetter, mapChainConfig, pancakePairV2ABI, tradeComparator, validateAndParseAddress };
